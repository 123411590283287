import { Helmet } from 'react-helmet';
import { useState, } from 'react';

import { Separator } from '@/components/ui/separator.jsx';



import { Text, Button, Divider, AbsoluteCenter, VStack, HStack, Icon } from '@chakra-ui/react';
// import { Tabs, TabList, TabPanels, Tab, TabPanel, Box,  } from "@chakra-ui/react";

import { GrSchedulePlay, GrValidate } from "react-icons/gr";
import { RiLightbulbFlashLine } from "react-icons/ri";

import imageIntegration from './v1/image-integration.png';
import imageAutomation from './v1/image-automation.png'; 
import AnimationTypingSearch from './v1/AnimationTypingSearch';
// import imageVisualization from '../ai/visualization-card.png';

import { ExplorePlans } from './v1_1/PlanCards';


const Slogan = () => {  
    return (
      <div className="max-w-screen-xl text-center px-4">
        <h1 className="font-bold md:text-5xl my-auto">
            AI First Databases
        </h1>
        <div className="mx-auto my-8">
        <h4>
        Accretion Database lets you turn static documents to knowledgebases for AI. 
        </h4>         
        </div>      
      </div>
    );
}; 

const ContentFormat = ({ title, description, image }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-5 gap-8'>
            
            <div className='col-span-2 mt-0 w-full'>
                <h1>
                {title}
                </h1>
                <h4>
                {description}
                </h4>
            </div>

            <div className='col-span-3 w-full p-4 rounded-3xl border-2 border-gray-200'>
                {image}
            </div>
        </div>
    )
};

const ContentIntegration = () => {
    const title = "Integration"; 
    const description = "Integrate documents into a knowledge base for AI automation."; 
    const image = <img src={imageIntegration} alt='image-integration'/>; 

    return (
        <ContentFormat title={title} description={description} image={image}/>
    );
}

const ContentEfficiency = () => {
    const title ="Efficiency";
    const description="Search a needle in a haystack through the entire database."
    const IllustrationSearch = () => {
        const [hoveredTerm, setHoveredTerm] = useState('default');
    
        const ButtonStyleSeachTerm = {
            variant: "outline", 
            color: "black", 
            border: "solid black",
            _hover: { color: 'black', bg: 'gray.200' },
        }

        const ButtonSearchTerm = ({ searchTerm }) => {
            return (
                <Button 
                    {...ButtonStyleSeachTerm} 
                    onMouseEnter={ () => {setHoveredTerm(searchTerm); console.log("button search term, ", searchTerm)}}
                >
                    {searchTerm}
                </Button>
            )
        }

        return( 
            <div className='row '>                    
                <div className='column' style={{flex:"3", minHeight:"200px", margin:"auto"}}>                        
                    <div className='row' style={{textAlign:"center", padding:"10px", width:"80%"}}>
                        <strong style={{fontSize:"25px"}}>
                            Simply use natural language:
                        </strong>
                    </div>
                    <div className='row' style={{textAlign:"center", padding:"10px", width:"80%"}}>
                        <AnimationTypingSearch searchTerm={ hoveredTerm }/>
                    </div> 
                </div>

                <div className='column' style={{flex:"1", fontSize:"18px"}}>
                    <div className='row' id='search-item'> 
                        <ButtonSearchTerm searchTerm="APN" />
                    </div>
                    <div className='row' id='search-item'> 
                        <ButtonSearchTerm searchTerm="Address" />
                    </div>
                    <div className='row' id='search-item'> 
                        <ButtonSearchTerm searchTerm="Name" />
                    </div>
                    <div className='row' id='search-item'> 
                        <ButtonSearchTerm searchTerm="Title number" />
                    </div>
                    <div className='row' id='search-item'> 
                        <ButtonSearchTerm searchTerm="Anything" />
                    </div>   
                </div>
            </div>   
        );
    };

    return (
        <ContentFormat title={title} description={description} image={<IllustrationSearch/>}/>
    );
}

const ContentAutomation = () => {
    const title = "Automation";
    const description = "Automate repetitive workloads using Accretion AI.";
    const AutomationTerms = () => {        
        const textStyle = {
            fontSize: "18px", 
            textAlign: "left",
            padding: "2px",
        }
        const iconStyle = {
            boxSize: 8,
            margin: "5px 10px 0 0", 
        }
        return (    
            <VStack spacing={2} maxWidth="80%">            
                <HStack  align="flex-start" spacing={3}  w="100%">
                    <Icon as={GrSchedulePlay} {...iconStyle}/>
                    <Text {...textStyle} >
                        Periodically check for record updates on county websites.
                    </Text>            
                </HStack> 
                <HStack  align="flex-start" spacing={3}  w="100%">
                    <Icon as={GrValidate} {...iconStyle}/>
                    <Text {...textStyle} >
                        Validate critical terms, such as legal descriptions, in all documents.
                    </Text>            
                </HStack>  
                <HStack  align="flex-start" spacing={3}  w="100%">
                    <Icon as={RiLightbulbFlashLine} {...iconStyle}/>
                    <Text {...textStyle} >
                        Talk to our team today to unlock endless possibilities for automating your tedious workloads. 
                    </Text>            
                </HStack>            
            </VStack>    
        );
    }; 
    const IllustrationAutomation = () => {
        return (
            <div>
                <img src={imageAutomation} alt='image-automation'/>
                <AutomationTerms/>
            </div>
        )
    }
    return (
        <ContentFormat title={title} description={description} image={<IllustrationAutomation/>}/>
    );
}

const SeperatorLine = () => {
    return (
        <div className=''>
            <Separator className="h-[2px] bg-gray-900 mt-16 mb-8"/>
        </div>
    );
}

export default function Database () {            
    return (
        <div className=''>
            <Helmet>
                <title> Database | Build AI Ready Databases </title>
            </Helmet>

            <div className='max-w-screen-xl px-4'>
                <div className='min-h-[50svh] flex justify-center items-center'>
                    <Slogan/>
                </div>                

                <div className='mb-32'>
                    <SeperatorLine/>
                    <ContentIntegration/>
                </div>

                

                <div className='mb-32'>
                    <SeperatorLine/>
                    <ContentEfficiency/>
                </div>                

                <div className='mb-32'>
                    <SeperatorLine/>
                    <ContentAutomation/>
                </div>                

                <div className='mb-32'>
                    <SeperatorLine/>
                    <h1 className="text-left">
                        Explore Plans
                    </h1>
                    <ExplorePlans/>
                </div>
            </div>
                        
        </div>
    )
}