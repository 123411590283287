import React, { useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardProperty, CardPropertyUserUpload } from './CardProperty';
import Error from '@/database-demo/loading-error-handling/Error';
import { createQueryString_home_AI } from '@/helper/createQueryString';


const urlBackendRecommendationV1= process.env.REACT_APP_BACKEND_DOMAIN + "/api/recommendation/v1/";
const urlBackendRecommendationUU= process.env.REACT_APP_BACKEND_DOMAIN + "/api/recommendation/user-upload/";

const Recommendation = () => {
    const [dataV1, setDataV1] = useState([]);
    const [dataUU, setDataUU] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDataV1 = async () => {
            try {
                const response = await fetch(urlBackendRecommendationV1);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();                
                setDataV1(data);                
            } catch (error) {
                setError(error.message);                
                console.error('Error fetching random properties:', error);
            }
        };

        const fetchDataUU = async () => {
            try {
                const response = await fetch(urlBackendRecommendationUU);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();                
                setDataUU(data);
                // console.log(data);
            } catch (error) {
                setError(error.message);                
                console.error('Error fetching random properties:', error);
            }
        };
        setIsLoading(true);
        fetchDataV1();
        fetchDataUU();
        setIsLoading(false);
    }, []);

    const handleClick = (property) => {
        // console.log(property);
        
        const addressInfo = property.addressInfo;
        const propertyID = property.propertyID;

        const address = `${addressInfo.street_number} ${addressInfo.route} ${addressInfo.locality} ${addressInfo.state} ${addressInfo.zipcode}`            
        const queryParams = new URLSearchParams({
            propertyid: propertyID,
            address: address,
        });

        // Handle button click        
        navigate(`/title-chat/?${queryParams.toString()}`);
        
    };

    const handleClickUserUpload = (userData) => {
        const queryString = createQueryString_home_AI(userData)                        
        navigate(queryString);        
    }

    return (        
        <div className=''>
          <div className="text-left py-2"> 
              <div className='text-3xl'> Suggestions for you </div>
              <div className='text-base'>
                Choose a property in Accretion database to talk with
              </div>
          </div>
          
          <div className='flex'>
              {error && <div> {error} <Error/> </div>}
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
            {isLoading 
                ? Array(3).fill().map((_, index) => <CardProperty key={index} isLoading={true} />)
                : dataUU.slice(0, 3).map(property => (
                    <CardPropertyUserUpload                      
                      key={property.propertyID}
                      previewLink={
                        property.pictures && property.pictures.length > 0 
                          ? property.pictures[0].document_link_presigned 
                          : "/logo192.png" // Replace with your fallback image path
                      }
                      address={property.address}
                      isLoading={false}
                      onClick={() => handleClickUserUpload(property)}
                    />
                  )) 
            }

            {isLoading 
                ? Array(3).fill().map((_, index) => <CardProperty key={index} isLoading={true} />)
                : dataV1.slice(0, 3).map(property => (
                    <CardProperty                      
                      key={property.propertyID}
                      addressInfo={property.addressInfo}
                      isLoading={false}
                      onClick={() => handleClick(property)}
                    />
                  )) 
            }                            
          </div>

        </div>
    );
};

export default Recommendation;