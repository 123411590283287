import { PublicClientApplication } from "@azure/msal-browser";

const clientIdMicrosoft = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
const baseURL = window.location.origin;

export const msalConfig = {  
  auth: {
    clientId: clientIdMicrosoft,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: `${baseURL}`
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);