import { useRef } from "react";
import Login from "../Login";
import { useAuth } from "../AuthContext";

import "../../overlay/overlay.css";

export default function LoginOverlay({}) {
    const { isLoggedIn } = useAuth();
    
    const overlayRef = useRef(null);
    // const history = useNavigate();    

    return (
        <div>
            {!isLoggedIn && (
                <div className="overlay-contact-us">
                    <div className="overlay-contact-us-content " ref={overlayRef}>
                        <div className="row" style={{minHeight:"70svh"}}>                        
                            <Login/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}