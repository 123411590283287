import { Helmet } from 'react-helmet';

import Demo from '../demo/v1/Demo_v1.js';

import "../database-demo/DatabaseDemo.css"
import "../database/Database.css"

export default function Home () {
    return (
        <div className='mb-32'>
            <Helmet>
                <title>Accretion | Accelerate Housing Transactions with AI </title>
            </Helmet> 
            
            <Demo/> 
        </div>
    )
}