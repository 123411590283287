import { SearchGoogleMap } from '@/helper/GoogleMapAPI';
import Recommendation from "@/recommendation/Recommendation";
// import LoginCard from "@/user-auth/LoginCard";
import { ExplorePlans } from '@/database/v1_1/PlanCards';

export default function DatabaseDemo () {
    return(
        <div>
        <div className='max-w-3xl flex flex-col gap-16 px-2'>
            <div className='w-full space-y-2'>
                <div>
                    <div className='text-3xl'>
                        Search
                    </div>
                    <div className='text-base'>
                        Search through the Accretion Database
                    </div>
                </div>
                <div className=''>
                    <SearchGoogleMap/>
                </div>
            </div>

            <div className='w-full'>
                <Recommendation/>
            </div>  

            {/* <div className="w-full space-y-2">
                <div>
                    <div className='text-3xl'>
                        Join Accretion
                    </div>
                    <div className='text-base'>
                        Search through the Accretion Database
                    </div>
                </div>
                <div className="text-left">
                    <LoginCard className="ml-0"/>
                </div>
            </div>             */}
        </div>

        <div className='max-w-screen-xl mt-32'>
            <div className='max-w-3xl'>
                <div className='text-3xl'>
                    Explore Plan
                </div>
                <div className='text-base'>
                    Get the best AI service for your real estate needs
                </div>
            </div>
            <div className='px-10'>
                <ExplorePlans/>
            </div>
        </div>
        </div>
    )
}