
const createQueryString_getData = ({ propertyID }) => {
    
    if (!propertyID) {
        console.error("createQueryString_title_report: propertyID is null");
        return null;
    }

    // create encoded query string 
    const encodedQueryString =  `?propertyID=${propertyID}&addressInfo=${null}`;
    
    return (encodedQueryString)
};


const createQueryString_title_chat = (chat) => {        
    if (!chat) {
        console.error("createQueryString_title_report: chat is null");
        return null;
    }
    const address = chat.addressOneLine;
    const propertyID = chat.propertyID;
    if (!address) {
        console.error("createQueryString_title_report: addressInfo is null");
        return null;
    }

    if (!propertyID) {
        console.error("createQueryString_title_report: propertyID is null");
        return null;
    }

    // create encoded query string 
    const encodedQueryString =  `/title-chat/?propertyid=${propertyID}&address=${encodeURIComponent(JSON.stringify(address))}`;
    
    return (encodedQueryString)
}

const createQueryString_home_AI = (userData) => {        
    if (!userData) {
        console.error("createQueryString_home_AI: userData is null");
        return null;
    }
    const address = userData.address;
    console.log(address)
    const propertyID = userData.propertyID;
    if (!address) {
        console.error("createQueryString_home_AI: addressInfo is null");
        return null;
    }
    // create encoded query string 
    const encodedQueryString =  `/home-ai/?propertyID=${propertyID}&address=${address}`;
    
    return (encodedQueryString)
}

const createQueryString_getDataHomeAI = ({ propertyID, address }) => {
    
    if (!propertyID) {
        console.error("createQueryString_title_report: propertyID is null");
        return null;
    }

    // create encoded query string 
    const encodedQueryString =  `?propertyID=${propertyID}&address=${address}`;
    
    return (encodedQueryString)
};

export { createQueryString_getData, createQueryString_title_chat, createQueryString_getDataHomeAI, createQueryString_home_AI }