import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { Toaster } from '@/components/ui/toaster';
// import { FormText } from 'react-bootstrap';

export default function RootLayout() {
    const location = useLocation();
    const hideFooter = location.pathname.startsWith('/title-chat') || location.pathname.startsWith('/home-ai');
    return (
        <div>
            <Header className=""/>
            <body>
                <main className="mt-24">                
                    <Outlet/>
                </main>
                <Toaster/>
            </body>            
            {
                (!hideFooter)
                && <Footer />
            }
        </div>
    )

};