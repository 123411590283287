import * as React from "react";

const IconLoading = () => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg5"
        width="60"
        height="60"
        version="1.1"
        viewBox="0 0 50.8 29.104"
        // className="animate-bounce"
      >
        <g id="layer1" transform="rotate(19 147.9 29.213)">
          <g
            id="g3613"
            fill="#000"
            strokeOpacity="1"
            transform="translate(9.342 23.041)"
          >
            <g
              id="g2622"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              transform="scale(.44345)rotate(-20 79.345 131.521)"
            >
              <path
                id="path2309-6"
                strokeWidth="2.54"
                d="M5.635 61.5c3.864.116 14.696.483 23.182.703 10.333.268 22.938.759 34.373.74 11.392-.02 23.866-.548 34.108-.85 8.396-.246 19.095-.647 22.913-.777-3.819-.117-14.519-.484-22.915-.704-10.243-.269-22.72-.757-34.111-.74-11.435.017-24.039.548-34.37.849-8.486.247-19.317.648-23.18.778"
              ></path>
              <path
                id="path2490"
                strokeWidth="2.54"
                d="M61.753 30.691c-1.66 0-4.228.13-6.956 1.01-1.249.403-2.366.907-3.382 1.472-.675.375-1.34.797-2.004 1.269-1.558 1.107-2.996 2.41-4.5 3.902-1.456 1.444-2.924 3.011-4.47 4.557-3.263 3.263-7.085 6.398-12.398 9.493a84 84 0 0 1-3.434 1.89c-2.842 1.484-5.925 2.94-9.708 4.355a87 87 0 0 1-9.23 2.877c2.211-.31 5.676-.794 9.588-1.675 3.912-.88 7.441-1.975 10.438-3.261a44 44 0 0 0 3.693-1.793c5.7-3.1 9.528-6.771 12.846-10.09 1.582-1.582 3.055-3.152 4.464-4.55 1.46-1.448 2.787-2.642 4.183-3.635a19 19 0 0 1 1.767-1.12 16.3 16.3 0 0 1 2.927-1.274c2.368-.763 4.637-.887 6.176-.887h2.34c1.54 0 3.809.124 6.177.887 1.078.348 2.044.783 2.927 1.274.589.327 1.174.699 1.767 1.12 1.396.993 2.722 2.187 4.183 3.635 1.409 1.398 2.882 2.968 4.463 4.55 3.32 3.319 7.148 6.99 12.847 10.09a44 44 0 0 0 3.694 1.793c2.996 1.286 6.525 2.38 10.437 3.26 3.912.882 7.377 1.365 9.588 1.676a87 87 0 0 1-9.23-2.877c-3.784-1.415-6.866-2.871-9.709-4.354a84 84 0 0 1-3.434-1.89c-5.312-3.096-9.134-6.231-12.396-9.494-1.547-1.546-3.015-3.113-4.472-4.557-1.504-1.491-2.941-2.795-4.499-3.902a21 21 0 0 0-2.005-1.27 19 19 0 0 0-3.382-1.471c-2.727-.88-5.295-1.01-6.956-1.01h-1.27z"
              ></path>
              <path
                id="path2490-3"
                strokeWidth="2.374"
                d="M61.902 95.052c-1.45 0-3.694-.13-6.075-1.01a15.4 15.4 0 0 1-2.954-1.472c-.59-.375-1.17-.797-1.75-1.269-1.36-1.107-2.617-2.41-3.93-3.902-1.272-1.444-2.554-3.011-3.905-4.557-2.849-3.263-6.187-6.398-10.826-9.493a71 71 0 0 0-3-1.89c-2.482-1.484-5.174-2.94-8.477-4.355a69 69 0 0 0-8.062-2.877c1.931.31 4.957.794 8.374 1.675 3.416.88 6.499 1.975 9.115 3.261a37 37 0 0 1 3.226 1.793c4.977 3.1 8.32 6.771 11.219 10.09 1.381 1.582 2.667 3.152 3.898 4.55 1.275 1.448 2.434 2.642 3.653 3.635q.774.63 1.543 1.12c.772.49 1.615.926 2.557 1.274 2.067.763 4.05.887 5.394.887h2.043c1.344 0 3.326-.124 5.394-.887a13.4 13.4 0 0 0 2.557-1.274 16 16 0 0 0 1.543-1.12c1.219-.993 2.377-2.187 3.653-3.635 1.23-1.398 2.516-2.968 3.898-4.55 2.898-3.319 6.242-6.99 11.22-10.09a37 37 0 0 1 3.225-1.793c2.616-1.286 5.699-2.38 9.115-3.26 3.417-.882 6.442-1.365 8.373-1.676a69 69 0 0 0-8.06 2.877c-3.304 1.415-5.997 2.871-8.48 4.354a71 71 0 0 0-2.998 1.89c-4.64 3.096-7.977 6.231-10.826 9.494-1.35 1.546-2.634 3.113-3.905 4.557-1.314 1.491-2.57 2.795-3.93 3.902-.58.472-1.16.894-1.75 1.27a15.4 15.4 0 0 1-2.954 1.471c-2.382.88-4.625 1.01-6.075 1.01h-1.109z"
              ></path>
            </g>
            <g
              id="g3391"
              stroke="#000"
              strokeWidth="0.457"
              transform="matrix(.39042 -.13443 .14443 .41946 -20.44 35.486)"
            >
              <path
                id="rect3365-9-7"
                d="M75.888-32.693h4.325v51.972h-4.325z"
                transform="matrix(.96807 .25068 -.4379 .89902 0 0)"
              ></path>
              <path
                id="rect3365-9"
                d="M-90.03 13.571h4.325v51.972h-4.325z"
                transform="matrix(-.96807 .25068 .4379 .89902 0 0)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    )
};

export { IconLoading };
