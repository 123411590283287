import { useJsApiLoader, GoogleMap, Autocomplete, Marker} from '@react-google-maps/api'; 
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Loader2 } from "lucide-react"

import { getData, getDataHomeAI } from '@/helper/getData';
import { Card, CardContent,} from "@/components/ui/card";

import { createQueryString_title_chat, createQueryString_getDataHomeAI } from "@/helper/createQueryString";


const API_key_google_maps = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; 
const libraries = ['places'];

const SearchGoogleMap = () => {
  const [address, setAddress] = useState();
  const [propertyID, setPropertyID] = useState();
  const mapRef = React.useRef();
  const [marker, setMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [center, setCenter] = useState({
      lat: 38.7946,
      lng: -95,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataAccretion, setDataAccretion] = useState(null);
  const [dataUserUpload, setDataUserUpload] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {    
    const fetchDataAccretion = async () => {
      const data = await getData({propertyID});
      if (data) {
        // console.log("Accretion data", data);
        setDataAccretion(data);
      } else{
        setDataAccretion(null);
      }
    }

    const fetchDataUserUpload = async () => {
      const data = await getDataHomeAI({propertyID, address});
      if (data) {
        // console.log("User upload data", data);
        setDataUserUpload(data);
      } else {
        setDataUserUpload(null);
      }
    }        
    
    setIsLoading(true);
    fetchDataUserUpload();
    fetchDataAccretion();
    setIsLoading(false);
  },[propertyID, address]);

  const ButtonTalkToAI = () => {
    const handleStartNow = () => {
      if (dataUserUpload) {        
        const query_string_home_AI = `?propertyID=${propertyID}&address=${address}`;
        navigate(`/home-ai/${query_string_home_AI}`);
      } else if (dataAccretion){
        const query_string_title_chat = createQueryString_title_chat(dataAccretion)
        navigate(`/title-chat/${query_string_title_chat}`);
      }
    }

    return( 
      <Button variant="outline" onClick={handleStartNow}>
        Start Now
      </Button>
    )
  }

  const ButtonUpload= () => {
    const handleUploadNow = () => {
      navigate('/user-upload')
    }

    return( 
      <Button variant="outline" onClick={handleUploadNow}>
        Upload Now
      </Button>
    )
  }


  const ActionCard = () => {
    
    return (
      <Card className="w-full h-full min-h-32 min-w-64 rounded-3xl border-2 border-gray-500 bg-white/80 backdrop-blur-sm">
        <CardContent className="flex flex-col gap-2 p-4 transparent">
          {isLoading ? (
            <div>
              <Button disabled variant="outline" className="w-full"> 
                <Loader2 className="animate-spin mr-4"/>
                Searching, please wait
              </Button>
            </div>
          ) : (
            <div>
              {dataUserUpload && (
                <div className='flex flex-col gap-2'>
                  <div>Start Accretion AI to talk with this property.</div>
                  <ButtonTalkToAI/>
                </div>
              )}
              
              {(!dataUserUpload && dataAccretion) && (
                <div className='flex flex-col gap-2'>
                  <div>Start Accretion AI to talk with this property.</div>
                  <ButtonTalkToAI/>
                </div>
              )} 

              {(!dataAccretion && !dataUserUpload) && (
                <div className='flex flex-col gap-2'>
                  <div>We can't find this property. Add documents to our database.</div>
                  <ButtonUpload/>
                </div>
              )}
            </div>
          )
          }

          
        </CardContent>
      </Card>
    )    
  }


  const containerStyle = {
      width: '100%',
      height: '400px'
  }; 

  const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: API_key_google_maps,
      libraries
  });    

  const onMapLoad = React.useCallback((map) => {
      mapRef.current = map;
  }, []);

  const onAutocompleteLoad = useCallback((autocomplete) => {        
      setAutocomplete(autocomplete);
  }, []);

  const onPlaceChanged = useCallback(() => {
      if (autocomplete) {
        const place = autocomplete.getPlace();
        setAddress(place.formatted_address);
        setPropertyID(place.place_id);
        // console.log(place);
        if (place.geometry) {            
          const newPosition = {
              lat: place.geometry.location.lat() + 0.00025, //offset to show action card better
              lng: place.geometry.location.lng() 
          };
          setCenter(newPosition);
          // Update map position
          mapRef.current?.panTo({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
          }); 
          // Set marker
          setMarker({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              title: place.formatted_address || place.name            
          });            
          // Zoom in to the selected location
          mapRef.current?.setZoom(17.5);            
        } else {
          console.warn("No geometry found for the selected place.");
        }
      }
  }, [autocomplete]);

  if(loadError) return "Error";
  if(!isLoaded) return "Maps"; 

  return (
      <div className='space-y-4'>
          <div >
              <Autocomplete
                  onLoad={onAutocompleteLoad}
                  onPlaceChanged={onPlaceChanged}
              >
                  <Input
                      type="text"
                      placeholder="Enter an address"
                      className="w-full px-4 py-2 text-base border rounded-3xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"                    
                  />
              </Autocomplete>
          </div>

          <div className='w-full relative'>
            {address && (
            <div className='max-w-80 z-50 absolute top-0 left-0 m-2'>
              <ActionCard/>
            </div>
            )}
            

            <div className='shadow-2xl rounded-3xl overflow-hidden w-full'>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={4}
                    onLoad={onMapLoad}
                >
                    {marker && (
                      <div>                        
                        <Marker
                            position={{ lat: marker.lat, lng: marker.lng }}
                            title={marker.title}
                        />                      
                      </div>
                    )}
                </GoogleMap>
            </div>
          </div>
          <style jsx global>{`
            .pac-container {
              z-index: 1000;                    
              border-radius: 0.75rem;            
              background-color: white; /* Ensure white background */
              margin-top: -80px;
              padding: 10px;
            }        
          `}</style>
      </div>       
  )
};

const DisplayGoogleMap = ({ setAddress, setPropertyID }) => {
    const mapRef = React.useRef();
    const [marker, setMarker] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [center, setCenter] = useState({
        lat: 38.7946,
        lng: -95,
    });    

    const containerStyle = {
        width: '100%',
        height: '400px'
    }; 

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: API_key_google_maps,
        libraries
    });    

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const onAutocompleteLoad = useCallback((autocomplete) => {        
        setAutocomplete(autocomplete);
    }, []);

    const onPlaceChanged = useCallback(() => {
        if (autocomplete) {
          const place = autocomplete.getPlace();
          setAddress(place.formatted_address);
          setPropertyID(place.place_id);
          // console.log(place);
          if (place.geometry) {            
            const newPosition = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            setCenter(newPosition);
            // Update map position
            mapRef.current?.panTo({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }); 
            // Set marker
            setMarker({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                title: place.formatted_address || place.name            
            });            
            // Zoom in to the selected location
            mapRef.current?.setZoom(17.5);            
          } else {
            console.warn("No geometry found for the selected place.");
          }
        }
    }, [autocomplete]);

    if(loadError) return "Error";
    if(!isLoaded) return "Maps"; 

    return (
        <div className='space-y-4'>
            <div >
                <Autocomplete
                    onLoad={onAutocompleteLoad}
                    onPlaceChanged={onPlaceChanged}
                >
                    <Input
                        type="text"
                        placeholder="Search for a location"
                        className="w-full px-4 py-2 text-base border rounded-3xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"                    
                    />
                </Autocomplete>
            </div>
            {/* <div className="mb-8 flex items-center">              
              <Switch/>              
              <Input
                type="text"
                placeholder="Unit"
                className="max-w-24 px-4 py-2 text-base border rounded-3xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"                    
              />
            </div> */}
            <div className='shadow-2xl rounded-3xl overflow-hidden'>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={4}
                    onLoad={onMapLoad}
                >
                    {marker && (
                        <Marker
                            position={{ lat: marker.lat, lng: marker.lng }}
                            title={marker.title}
                        >                            
                        </Marker>
                        
                    )}
                </GoogleMap>
            </div>
            <style jsx global>{`
              .pac-container {
                z-index: 1000;                    
                border-radius: 0.75rem;            
                background-color: white; /* Ensure white background */
                margin-top: -80px;
                padding: 10px;
              }        
            `}</style>
        </div>       
    )
}

export { SearchGoogleMap, DisplayGoogleMap };
