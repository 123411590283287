import { useRef, useEffect } from "react";

import { Card, CardContent, CardTitle } from "@/components/ui/card";

import { AspectRatio } from "@/components/ui/aspect-ratio";

import cn from "classnames";

export function DemoVideoCard({ videoFilepath, title, isActive, aspectRatio = 16 / 9, className}) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (isActive && videoRef.current) {
      // Play the video when isActive is true
      videoRef.current.play();
    } else if (!isActive && videoRef.current) {
      // Pause the video when isActive becomes false (optional)
      videoRef.current.pause();
    }
  }, [isActive]);

  return (                   
    <Card class="max-w-screen-lg">
      <CardTitle class="text-center"> {title} </CardTitle>
        <CardContent class="p-0">
          <AspectRatio ratio={aspectRatio} className={cn("relative overflow-hidden", className)} >
            <video 
              ref={videoRef}
              src={videoFilepath}
              width="100%" 
              height="100%" 
              controls={false} 
              autoPlay={isActive}
              loop={false}
              muted 
              playsInline
              className=""
            />
          </AspectRatio>
        </CardContent>
    </Card>                
  )
}