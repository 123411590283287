import { useAuth } from '../user-auth/AuthContext';
import { Sidebar } from '@/components/sidebar/Sidebar';
import Logout from '../user-auth/Logout'; 
import LoginCard from '@/user-auth/LoginCard';
import { RecentChatShort, ButtonViewAllChats } from './Recents'; 
import Recommendation from '@/recommendation/Recommendation';
import { ExplorePlans } from '@/database/v1_1/PlanCards';
import { SearchGoogleMap } from '@/helper/GoogleMapAPI';

export default function You () {
    const { isLoggedIn } = useAuth(); 
    
    return (
        <div className=''>            
            <div className="">
                <Sidebar/>
            </div>
            
            <div className='max-w-3xl flex flex-col gap-16 px-4 md:px-2'>
                <div className='w-full space-y-2'>
                    <div>
                        <div className='text-3xl'>
                            Search
                        </div>
                        <div className='text-base'>
                            Search through the Accretion Database
                        </div>
                    </div>
                    <div className=''>
                        <SearchGoogleMap/>
                    </div>
                </div>
            
                <div className='w-full'>
                    <Recommendation/>
                </div>                        

                {isLoggedIn ? (
                    <div className='w-full'>
                        <div className='flex justify-between w-full mb-2'>
                            <div className="ml-0 text-left"> 
                                <div className='text-3xl'> Your conversations </div>
                                <div className='text-base'>Get back to your recent chats</div>
                            </div>
                            <div className='mr-0 '>
                                <ButtonViewAllChats/>
                            </div>                
                        </div>
                        <RecentChatShort />
                    </div>
                ):(
                    <div className='w-full space-y-8'>
                        <div>
                            <div className='text-3xl'>
                                Join Accretion
                            </div>
                            <div className='text-base'>
                                Access the full Accretion AI feature by creating an account, or sign back in.
                            </div>
                        </div>
                        <LoginCard/>
                    </div>
                )}                
            </div>

            <div className='max-w-screen-xl mt-32 '>
                <div className='max-w-3xl px-4'>
                    <div className='text-3xl'>
                        Explore Plan
                    </div>
                    <div className='text-base'>
                        Get the best cusomitzed AI service from Accretion AI team.
                    </div>
                </div>
                <div className='px-4 md:px-10'>
                    <ExplorePlans/>
                </div>
            </div>

            <div className='max-w-3xl my-16'>
                <Logout/>
            </div>
            
            
        </div> 
    );
}