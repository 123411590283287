import { Helmet } from 'react-helmet';

import { Separator } from '@/components/ui/separator.jsx';

import { Button } from '@/components/ui/button.jsx';

// import AboutUs from '../about-us/AboutUs.js'; 
// import ContactUs from '../contact-us/ContactUs.js';
// import Leadership from './Leadership.js';
// import Career from './Career.js';

import './Company.css'; 

const SeperatorLine = () => {
    return (
        <div className='mx-4'>
            <Separator className="h-[2px] bg-gray-900 mt-16 mb-8"/>
        </div>
    );
}

const ButtonEmail = ({ buttonText, emailAddress }) => {
    return (
        <Button 
            variant="outline" 
            className="w-full text-lg border-2 border-gray-500 hover:rounded-3xl"
            onClick={() => window.location.href = emailAddress}
        >
            {buttonText}
        </Button>
    );
};

const Slogan = () => {  
    return (
      <div className="max-w-screen-xl text-center px-4">
        <h1 className="font-bold md:text-5xl my-auto">
        Accelerate real estate transactions using AI 
        </h1>
        <div className="mx-auto my-8">
        <h4>
        Accretion is building databases and AI applications to automate tasks in title production. 
        </h4>         
        </div>      
      </div>
    );
}; 

const MissionStatement = () => {
    return (
        <div className="max-w-screen-xl">

            {/* <div className='min-h-96 grid grid-col mx-4 '>

                <div className="text-center m-auto"> 
                    <h1 className='my-4 font-bold'>
                        Accelerate real estate transactions using AI 
                    </h1>
                    <h5 className='my-4'>
                        Accretion is building databases and AI applications to automate tasks in title production. 
                    </h5>
                </div> 

            </div> */}

            <div className='min-h-[50svh] flex justify-center items-center'>
                <Slogan/>
            </div>

            <SeperatorLine/>

            <div className='grid grid-cols-1 md:grid-cols-5 mx-4 gap-8'>
                <h3 className='text-left font-bold mb-4'>
                Our mission 
                </h3>
                <h2 className='text-left col-span-4'>
                We believe artificial intelligence is making a profound impact on our society. 
                Accretion is integrating AI technologies into easy-to-use systems for the real estate title industry. 
                </h2>
            </div>

            <SeperatorLine/>

            <div className='grid grid-cols-1 md:grid-cols-5 mx-4 gap-8'>
                
                <h3 className='text-left font-bold mb-4'>
                    What we do 
                </h3>
                                
                <h2 className='text-left mt-0 md:col-span-4'>
                    Every transaction in real estate, the world’s largest asset class, 
                    relies on title insurance. 
                    
                    We are a startup building AI that reduces title insurance risk, 
                    delivers higher-quality title reports in significantly less time. 
                </h2>

                <div className='md:col-span-2 md:col-start-2 mt-0'>
                    <h3> 
                        <strong>AI in your inbox</strong> 
                    </h3>
                    <h5>
                    We bring AI directly to your email inbox, 
                    making it easier than ever to access AI for your title work.
                    </h5>
                </div>

                <div className='md:col-span-2 mt-0'>
                    <h3 className="font-bold"> 
                    Get closer to your clients
                    </h3>
                    <h5>
                    Promote the importance of title insurance directly to homeowners 
                    by allowing them to chat with your title work.
                    </h5>
                </div>
                
                <div className='md:col-span-2 md:col-start-2 mt-0'>
                    <h3 className="font-bold"> 
                    Work together 
                    </h3>
                    <h5>
                    We collaborate directly with title companies to design and build the best AI experience. 
                    Contact us to schedule a free meeting. 
                    </h5>
                </div>

                <div className='md:col-span-2 mt-0'>
                    <h3 className="font-bold"> 
                    Build AI-first databases
                    </h3>
                    <h5>
                    We bring our expertise in building databases optimized for 
                    implementing AI into your existing enterprise databases. 
                    </h5>
                </div>

                <div className='md:col-span-2 md:col-start-2 mt-0'>
                    <h3 className="font-bold"> 
                    Train AI models
                    </h3>
                    <h5>
                    Accretion trains and fine-tunes AI models that excel in logical reasoning 
                    for title examination, text validation, and beyond. 
                    </h5>
                </div>

                <div className='md:col-span-2'>
                    <ButtonEmail 
                        buttonText={"Email our team"} 
                        emailAddress={"mailto:support@accretiondb.com"}
                    />
                </div>

            </div>

            <SeperatorLine/>

            <div className='grid grid-cols-1 md:grid-cols-5 mx-4 gap-8'>
                
                <h3 className='text-left font-bold mb-4'>
                    Leadership
                </h3>

                <div className='text-left md:col-span-4'>
                    <h2>
                    Yiqi Ni | Founder & CEO 
                    </h2>

                    <h5>
                    After graduating in 2023 from MIT with a Ph.D. in physics, 
                    Yiqi founded Accretion with a passion for AI, financial statistics, physics models, 
                    and experimentation.

                    Yiqi created Accretion to rethink the way we transact real estate 
                    by integrating AI into every aspect of the transaction process, 
                    from title abstraction and examination to report production.
                    </h5>

                    <div className='max-w-72 my-8'>
                        <ButtonEmail 
                            buttonText={"Email Yiqi Ni"}
                            emailAddress={'mailto:yiqi@accretiondb.com'}
                        />
                    </div>
                </div>
            </div>

            <SeperatorLine/>

            <div className='grid grid-cols-1 md:grid-cols-5 mx-4 gap-8'>
                <h3 className='text-left font-bold mb-4'>
                    Join us
                </h3>

                <div className='text-left col-span-4'>
                    <h3>
                    At Accretion, we believe taking risks in building new things, 
                    and in bringing unique perspectives to all that we do. 
                    Unlock your potential at Accretion. 
                    Where bold innovators build groundbreaking AI models and databases for real estate transactions. 
                    Together we can build, sell, support world's leading developer for real estate data system.
                    </h3>

                    <div className='max-w-72 my-8'>
                        <ButtonEmail 
                            buttonText={"Email Us Your Resume"} 
                            emailAddress={"mailto:career@accretiondb.com"}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default function Company () {
    return (
        <div className='my-16'>
            <Helmet>
                <title>Company | Accretion</title>
            </Helmet>
                        
            <MissionStatement />
            {/* <AboutUs /> */}
            {/* <ContactUs /> */}
            {/* <Leadership /> */}
            {/* <Career /> */}
            
        </div>
    )
}