import { useEffect, useRef, useState } from 'react';
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

import { Loader2 } from "lucide-react"; 
import ReactMarkdown from 'react-markdown';
import { IconLoading } from '@/components/custom-chat/IconLoading';
import {
    Drawer,
    DrawerContent,
    DrawerTrigger,
} from "@/components/ui/drawer";
import { DocumentColumn } from '@/helper/DocumentColumn';

const getResponse = async (messageUser, propertyID) => {
    const urlBackendTitleChat= process.env.REACT_APP_BACKEND_DOMAIN + "/api/title-RAG/title-chat/"; 
    const csrftoken = document.cookie.split('; ').find(row => row.startsWith('csrftoken=')).split('=')[1];

    try {
        const response = await fetch(urlBackendTitleChat + `?messageUser=${messageUser}&propertyID=${propertyID}`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
            "propertyID": propertyID,
            "messageUser": messageUser,
        })
        });

        if (response.ok) {
            const data = await response.json();
            // console.log("get response: ", data);
            return data;
        } else {
            console.error("Failed to fetch title report. Please try again.");
            return null; 
        }
    } catch (error) {
        console.error(error);
        return null;
    } 
};

const getChatHistory = async (propertyID) => {
    const urlBackendChatHitory= process.env.REACT_APP_BACKEND_DOMAIN + "/api/title-RAG/chat-history/";

    try {
        const response = await fetch(`${urlBackendChatHitory}?propertyID=${encodeURIComponent(propertyID)}`, {
            method: "GET",
            credentials: "include" // Include cookies if needed for authentication
        });
        if (response.ok) {
            const data = await response.json();
            // console.log(data);
            return data.chat_history;
        } else {
            console.error("Failed to fetch chat history.");
            return null;
        }
    } catch (error) {
        console.error("An error occurred while fetching chat history.");
        return null;
    }
}

const DrawerDocument = ({recentChats}) => {
    return (
        <Drawer>
          <DrawerTrigger asChild>
            <Button variant="outline">View Documents</Button>
          </DrawerTrigger>
          <DrawerContent>
            <DocumentColumn data={recentChats}/>
          </DrawerContent>
        </Drawer>
      )
};

const ChatInput = ({propertyID, setChatHistory, isLoading, setIsLoading, setFirstOpen, recentChats}) => {
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);

    const resizeTextarea = () => {
        const textarea = textareaRef.current;
        if (!textarea) return;

        // Reset height to auto to get proper scrollHeight
        textarea.style.height = 'auto';
        
        // Calculate the number of lines
        const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
        const padding = parseInt(getComputedStyle(textarea).paddingTop) + 
                    parseInt(getComputedStyle(textarea).paddingBottom);
        
        // Set max height to 5 lines plus padding
        const maxHeight = (lineHeight * 12) + padding;
        
        // Get the required height based on content
        const newHeight = Math.min(textarea.scrollHeight, maxHeight);
        
        // Set the new height
        textarea.style.height = `${newHeight}px`;
    };

    useEffect(() => {
        resizeTextarea();
    }, [message]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (message.trim()) {
            console.log("ChatUI: start API call to backend...");
            const newMessage = message.trim();
            setChatHistory((prevChats) => [
                ...prevChats,
                { message: newMessage},
            ]);
            setMessage('');
            setIsLoading(true);
            setFirstOpen(false);
            const newResponse = await getResponse(message.trim(), propertyID);
            if (newResponse) {
                console.log("ChatUI: complete API call to backend");                
                setChatHistory((prevChats) => [
                    ...prevChats,
                    { response: newResponse },
                ]);
            } else {
                setMessage(newMessage);
                console.error("ChatUI: error getting response from backend.");
            }
            setIsLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <div className="">
            <div className='block md:hidden flex justify-center mb-2'>
                <DrawerDocument recentChats={recentChats}/>
            </div>
            
            <form onSubmit={handleSubmit} className="container border-2 border-gray-400 rounded-3xl bg-white">
            <div className="flex gap-2 ">
                <Textarea
                    ref={textareaRef}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="How can Accretion AI help you today?"
                    className="min-h-[36px] text-base  border-0 border-red-500 resize-none p-2 bg-transparent rounded-none focus-visible:ring-offset-0 focus-visible:ring-0"
                    rows={1}
                />
                {message.trim() && (
                    <Button type="submit" variant="outline" size="icon" className="border-0">
                        <FaRegArrowAltCircleUp className="h-6 w-6"/>
                    </Button>
                )}
                {isLoading && (
                    <Loader2 className="animate-spin"/>
                )}
            </div>
            </form>
        </div>
    );
};

const ChatSuggestions = ({ propertyID, setChatHistory, setIsLoading, setFirstOpen }) => {
    const ListSuggestion = [
        "Compare all legal descriptions in documents.", 
        "List all mortgage reconveyances.", 
        "Summarize existing easements of the property.",
        "List liens against the property.",
    ];

    const handleClick = async (suggestion) => {             
        console.log("ChatUI: start API call to backend...");
        setChatHistory((prevChats) => [
            ...prevChats,
            { message: suggestion},
        ]);        
        setIsLoading(true);
        setFirstOpen(false);
        const newResponse = await getResponse(suggestion, propertyID);
        if (newResponse) {
            console.log("ChatUI: complete API call to backend");
            setChatHistory((prevChats) => [
                ...prevChats,
                { response: newResponse },
            ]);
        } else {            
            console.error("ChatUI: error getting response from backend.");
        }
        setIsLoading(false);
    }

    const ButtonSuggestion = ({content}) => {
        return (
            <Button 
                variant="outline"
                onClick={() => handleClick(content)}
            >
                {content}
            </Button>
        );
    };

    return (
        <div>
            <div className=''>
                {/* <p>Hi there, how can I assist you with title work regarding the property at ${address}?</p> */}
                <p>Hi there, how can I assist you with title work regarding the property?</p>
                {ListSuggestion.map((suggestion, index)=>(
                    <ButtonSuggestion key={index} content={suggestion}/>
                ))}
            </div>
        </div>
    )
}

const ChatMessages = ({chatHistory, isLoading, firstOpen, setChatHistory, propertyID, setIsLoading, setFirstOpen}) => {    
    const bottomRef = useRef(null);

    const CustomMessage = ({ message }) => {
        return (              
            <ReactMarkdown>{message}</ReactMarkdown>
        );
    };   

    useEffect(() => {
        if (bottomRef.current) {
            if (firstOpen) {
                bottomRef.current.scrollIntoView();
            } else {
                bottomRef.current.scrollIntoView({ behavior: 'smooth' });
            }          
        }
    }, [chatHistory, isLoading]);

    if (chatHistory.length === 0) {
        return(
            <ChatSuggestions 
                propertyID={propertyID} 
                setChatHistory={setChatHistory}
                setIsLoading={setIsLoading}
                setFirstOpen={setFirstOpen}
            />
        )
    }

    return (
        <div className="mb-8">
            <div className='mb-2'>
                <ChatSuggestions 
                    propertyID={propertyID} 
                    setChatHistory={setChatHistory}
                    setIsLoading={setIsLoading}
                    setFirstOpen={setFirstOpen}                
                />
            </div>
            {chatHistory.map((chat, index) => (
                <div key={index} className="flex flex-col space-y-2">
                    {chat.message && (
                        <div className="h-full w-full bg-gray-100 rounded-3xl py-2 px-4 mb-2">
                            {chat.message}
                        </div> 
                    )}
                    {(!firstOpen && chatHistory.length === index+1) && (
                        <div ref={bottomRef} />
                    )}
                    {chat.response && (
                        <div className="h-full w-full px-4 py-2 border-1 border-gray-100 rounded-3xl mb-2">
                            <CustomMessage message={chat.response}/>
                        </div>
                    )}
                </div>
            ))}            
            {isLoading && (
                <div className="flex justify-start animate-bounce">
                    <div className="ml-4">
                        <IconLoading/>
                    </div>
                </div>
                
            )}
            {!isLoading && (
                <div className='flex justify-between '>
                    <div className="ml-4 ">
                        <IconLoading/>
                    </div>
                    <p className='mr-2 h-full mt-auto mb-3 text-gray-600 text-sm'>Accretion AI may make mistakes.</p>
                </div>
            )}
            {firstOpen && (
                <div ref={bottomRef} />
            )}
            
        </div>
    );
}

const ChatUI = ({ propertyID, recentChats }) => {
    const [chatHistory, setChatHistory] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);
    const [firstOpen, setFirstOpen] = useState(true);

    useEffect(() => {
        if (propertyID) {
            setFirstOpen(true);
            const fetchChatHistory = async () => {
                const history = await getChatHistory(propertyID);
                setChatHistory(history);
            };
            fetchChatHistory();
        };
    }, [propertyID]);

    return (
        <div className="relative flex flex-col h-[calc(100svh-6rem)]">
            <div className="flex-1 w-full h-full overflow-y-scroll">
                <ChatMessages 
                    chatHistory={chatHistory} 
                    isLoading={isLoading}
                    firstOpen={firstOpen}
                    setChatHistory={setChatHistory}
                    propertyID={propertyID}
                    setIsLoading={setIsLoading}
                    setFirstOpen={setFirstOpen}
                />
            </div>
            <div className="sticky bottom-0 w-full mb-2">
                <ChatInput 
                    propertyID={propertyID} 
                    setChatHistory={setChatHistory} 
                    isLoading={isLoading} 
                    setIsLoading={setIsLoading}
                    setFirstOpen={setFirstOpen}
                    recentChats={recentChats}
                />
            </div>            
        </div>
    );
};

export { ChatInput, ChatMessages, ChatUI };