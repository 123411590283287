import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/user-auth/AuthContext";
import LoginCard from "@/user-auth/LoginCard";
import Logout from "@/user-auth/Logout";
import { getChatHistory } from "@/helper/getData";
import { FiUserPlus } from "react-icons/fi"; 
import { ButtonViewAllChats } from "@/you/Recents";

import { 
    Sheet, 
    SheetContent,
    SheetHeader,
    SheetTitle,    
} from "@/components/ui/sheet";
import { Card } from "@/components/ui/card";

import { PiSidebarSimpleThin } from "react-icons/pi";

import { createQueryString_title_chat, createQueryString_home_AI } from "@/helper/createQueryString";

const Sidebar = () => {
    const { isLoggedIn } = useAuth();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [chatV1, setChatV1] = useState([]);
    const [chatUU, setChatUU] = useState([]);
    
    let closeTimeout;

    const handleMouseEnter = () => {
        if (closeTimeout) clearTimeout(closeTimeout);
        setOpen(true);
    };

    const handleMouseLeave = () => {
        closeTimeout = setTimeout(() => {
            setOpen(false);
        }, 300); // 300ms delay before closing
    };

    useEffect(()=>{
        const fetchChatHistory = async () => {
            const data = await getChatHistory();
            // console.log(data);
            setChatV1(data.recent_chat_v1);
            setChatUU(data.recent_chat_user_upload);
        };

        if (isLoggedIn) {
            fetchChatHistory();
        }
    },[isLoggedIn])

    const ChatContent = () => {
        return (
            <div className="relative h-full flex flex-col">
                <SheetHeader className="w-full h-16 py-4">
                    <SheetTitle>
                        Chat History
                    </SheetTitle>
                </SheetHeader>

                <div className="w-full grow overflow-y-scroll p-2 space-y-2">
                    {chatUU.length > 0 && (
                    chatUU.slice(0,4).reverse().map((chat, index) => (
                        <Card 
                            key={index}
                            className="w-full p-2 mb-0 hover:bg-gray-50 cursor-pointer transition-colors space-y-2"
                            onClick={() => handleClickHomeAI(chat)}
                        >
                            <div className="text-sm truncate">
                                {chat.address}
                            </div>
                            <div className="w-full flex">
                                <div className="ml-0 mr-2">
                                    <FiUserPlus className="w-6"/>                                    
                                </div>
                                <time className="text-xs text-gray-400 ml-2">
                                    {timeSinceInWeeks(chat.most_recent_timestamp)} ago
                                </time>
                            </div>
                        </Card>
                    )))}
                    {chatV1.length > 0 && (
                    chatV1.slice(0,4).map((chat, index) => (
                        <Card 
                            key={index}
                            className="w-full p-2 mb-0 hover:bg-gray-50 cursor-pointer transition-colors space-y-2"
                            onClick={() => handleClick(chat)}
                        >
                            <div className="text-sm truncate">
                                {chat.addressOneLine}
                            </div>
                            <div className="w-full flex">
                                <div className="ml-0 mr-2">                                    
                                    <img src="/logo192.png" className="w-6"/>
                                </div>
                                <time className="text-xs text-gray-400 ml-2">
                                    {timeSinceInWeeks(chat.most_recent_timestamp)} ago
                                </time>
                            </div>
                        </Card>
                    )))}
                    <div className="my-4">
                        <ButtonViewAllChats/>
                    </div>
                </div>
                                                
                <div className="sticky h-32 w-full py-4 bottom-0">
                    <Logout/>
                </div>                
                
            </div>
        )
    }

    const LoginContent = () => {
        return (
            <div>
                <SheetHeader className="w-full h-16 py-4">
                    <SheetTitle>                    
                    Login or Signup
                    </SheetTitle>
                </SheetHeader>
                <div className="my-16 mx-4">
                    <LoginCard/>
                </div>                
            </div>
        )
    }

    const handleClick = ( chat ) => {
        // console.log("handleCick: ", chat)
        if (!chat){
            return null;
        }                        
        const query_string = createQueryString_title_chat(chat);         
        navigate(query_string);
        setOpen(false);
    }

    const handleClickHomeAI = ( chat ) => {
        // console.log("handleCick: ", chat)
        if (!chat){
            return null;
        }                        
        const query_string = createQueryString_home_AI(chat);         
        navigate(query_string);
        setOpen(false);
    }

    const timeSinceInWeeks = (isoTimestamp) => {
        const date = new Date(isoTimestamp);
        const now = new Date();
        
        // Calculate the difference in milliseconds and convert to weeks
        const differenceInMs = now - date;
        const differenceInWeeks = Math.floor(differenceInMs / (1000 * 60 * 60 * 24 * 7));
        
        return differenceInWeeks > 0 
          ? `${differenceInWeeks}w` 
          : "<1w";
    }

    return (
        <div className="h-full">
            <div 
                className="fixed left-0 top-0 w-12 h-full z-50 flex items-end justify-center"
                onMouseEnter={handleMouseEnter}
            >
                <div className="mb-16 w-full">
                    <PiSidebarSimpleThin size={24}/>
                </div>
            </div> 

            <Sheet open={open} onOpenChange={setOpen}>
                <SheetContent 
                    side="left" 
                    className="w-96 h-full p-0 rounded-r-3xl bg-white/70 backdrop-blur-sm"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                {isLoggedIn ? (
                    <ChatContent/>
                ):(
                    <LoginContent/>
                )}
                
                
                </SheetContent>
            </Sheet>
        </div>
    );
}; 

export { Sidebar };