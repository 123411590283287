import { useAuth } from './AuthContext';
import { Button } from '@/components/ui/button';
import { FcGoogle } from "react-icons/fc";
import { FaMicrosoft } from "react-icons/fa";
import scrollToTop from '@/helper/scrollToTop';

const urlBackendAuth = process.env.REACT_APP_BACKEND_DOMAIN + "/api/auth/";

// logout button 
export default function Logout () {
    const { logout, isLoggedIn, user, authProvider } = useAuth(); 

    const handleLogout = async () => {
        try {
            const response = await fetch(urlBackendAuth + 'logout/', {
                method: 'POST',
                credentials: 'include', // Ensure cookies are sent with the request
            });
            if (response.ok) {
                logout(); 
                scrollToTop();
            } else {
                console.error('Logout failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };
    
    if (isLoggedIn) {
        return (
            <div className='flex flex-col gap-4'>
                <div className='flex'>
                    Logged in as 
                    {authProvider==='google' && (
                        <FcGoogle class="w-6 h-6 mx-2"/>
                    )} 
                    {authProvider==='microsoft' && (
                        <FaMicrosoft class="w-6 h-6 mx-2"/>
                    )} 
                    {user.username}
                </div>
                <div>
                    <Button 
                        onClick={handleLogout}
                        variant='outline'
                        className="w-64 rounded-3xl"
                    >
                        Logout 
                    </Button>
                </div>
                
            </div>
        )
    } else {
        return null;
    }    
};