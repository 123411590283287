import { Input } from "@/components/ui/input"; 
import { Textarea } from "@/components/ui/textarea";
import { Button } from '@/components/ui/button';

import { InlineWidget } from 'react-calendly';

import { useForm, ValidationError } from '@formspree/react';


const MessageUsComponent = () => {  
    
    const [state, handleSubmit] = useForm("mdoqpnlr");

    if (state.succeeded) {
        return (
            <div className='row'>
                <div id='text'>
                    Thanks for contacting us! 
                    <br/>
                    Our support team will get back to you within 2 business days. 
                </div>
            </div>
        );
    }

    return (        
        <form onSubmit={handleSubmit} className='grid grid-cols-1 gap-4 p-4 text-lg'>
            <Input
                className='rounded-xl text-lg border-2 border-gray-400'
                type="email" 
                name="email"
                placeholder='Your Email'
            />
            <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
            />            

            <Input
                className='rounded-xl text-lg border-2 border-gray-400'
                type="text"
                name="name"
                placeholder='Your Name'
            />    
        
            <Input
                className='rounded-xl text-lg border-2 border-gray-400'
                type="tel"
                name="phone-number"
                placeholder='Phone Number'
            />
            
            <Textarea
                className='rounded-xl text-lg h-72 border-2 border-gray-400'
                id="message"
                name="message"
                placeholder="Tell Us More About How We can Help"
            />
            <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
            />            
            
            <Button 
                className="rounded-xl text-lg border-2 border-gray-400"
                variant="outline" 
                type="submit" 
                disabled={state.submitting}
            >
                Submit
            </Button>            
            
        </form>        
    );
};

const ButtonEmail = ({ buttonText, emailAddress }) => {
    return (
        <Button 
            variant="outline" 
            className="w-full text-base text-left border-2 border-gray-500 hover:rounded-3xl"
            onClick={() => window.location.href = emailAddress}
        >
            {buttonText}
        </Button>
    );
};

export default function ContactUs ({ message }) {
    if (!message) {
        message = "Please let us know how we can assist you. Your feedback allows us to build a modern database and marketplace for a more efficient housing market."
    }

    
    return (
        <div className="max-w-screen-xl my-40">            
            
            <div className='grid grid-cols-1 md:grid-cols-3 mx-4 my-16 gap-8'>
                <div className='col-span-1 m-0'>
                    <h1 className='font-bold'>
                        Contact Accretion  
                    </h1>
                    <h5 className='text-lg'>
                        Connect with our team to get the latest update and additional resources. 
                    </h5>
                </div>

                <div className='col-span-1 md:col-span-2 mt-0 w-full rounded-3xl bg-gray-100'>
                    <MessageUsComponent/>
                </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-3 mx-4 my-16 gap-8'>
                <div className='col-span-1 m-0'>
                    <h1 className='font-bold'>
                        Schedule a meeting
                    </h1>
                    <h5 className='text-lg'>
                        Create an appointment with our team to get a demo of Accretion's latest product.
                    </h5>
                </div>

                <div className='col-span-1 md:col-span-2 mt-0 w-full rounded-3xl bg-gray-100'>
                    <InlineWidget
                        url="https://calendly.com/yiqinix/15min"
                        styles={{
                            height: '1100px', 
                        }}
                    />
                </div>

            </div>
            
            <div className='grid grid-cols-1 md:grid-cols-3 mx-4 my-16 gap-8'>
                <div className='col-span-1 m-0'>
                    <h1 className='font-bold'>
                        Reach out to us 
                    </h1>
                    <h5 className='text-lg'>
                        Pay us a visit. 
                    </h5>

                    <h5 className='text-lg mt-2'>
                        <strong>San Francisco Office</strong>
                        <div className="">
                        550 California St <br/>
                        San Francisco <br/>
                        California 94104 <br/>
                        </div>
                    </h5>
                </div>

                <div className='col-span-1 md:col-span-2 w-full rounded-3xl bg-gray-100 text-left p-4'>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        <h5> Customer support </h5> 
                        <ButtonEmail buttonText={"support@AccretionDB.com "} emailAddress={"mailto:support@AccretionDB.com"}/> 
                        <h5> Job openings </h5>
                        <ButtonEmail buttonText={"career@AccretionDB.com "} emailAddress={"mailto:career@AccretionDB.com "}/> 
                        <h5> Try AI via email </h5>
                        <ButtonEmail buttonText={"AI@AccretionDB.com "} emailAddress={"mailto:AI@AccretionDB.com "}/> 
                        <h5> Investor relation </h5>
                        <ButtonEmail buttonText={"Yiqi@AccretionDB.com "} emailAddress={"mailto:Yiqi@AccretionDB.com "}/> 
                        <h5> Call or text </h5>
                        <ButtonEmail buttonText={"720-225-7668"} emailAddress={"tel:Yiqi@AccretionDB.com "}/>
                    </div>
                </div>

            </div>
            
        </div>
    )
}
