// import react related
import { useState, useEffect } from "react"; 
import { Link } from 'react-router-dom'; 

import { MdOutlineAttachEmail, MdOutlineChat, MdOutlineBuild, MdLoop } from "react-icons/md";
import { CgAttachment } from "react-icons/cg";
import { PiBookOpenText } from "react-icons/pi";

// import ShadCN UI
import {
  Carousel,
  CarouselContent,  
  CarouselNext,
  CarouselPrevious,
  CarouselItem,
} from "@/components/ui/carousel" ;

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"; 

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import { Button } from "@/components/ui/button";

import Autoplay from "embla-carousel-autoplay";

// import custom components
import { useAuth } from "@/user-auth/AuthContext";

import scrollToTop from "@/helper/scrollToTop";

import { ExplorePlans } from "@/database/v1_1/PlanCards";

import LoginCard from "@/user-auth/LoginCard";

import { DemoVideoCard } from "./DemoVideoCard_v1";

import ImageBuild from "../../database/v1/image-integration.png";
import { Card, CardContent} from "@/components/ui/card";

const Slogan = () => {
  const ActionCard = ({header, text, buttonText, buttonLink, variant="outline"}) => {
    return (
      <Card className="rounded-3xl p-0 bg-gray-50 h-full"> 
        <CardContent className="text-lg mt-4 mb-0 grid grid-rows-[auto,1fr,auto]">
          <p className="text-xl font-bold">
            {header}
          </p>          
          <p className="text-lg">
            {text}
          </p>
          <Link to={buttonLink} className="w-full">
          <Button
            variant={variant}
            onClick={scrollToTop}
            className="w-full text-lg rounded-3xl border-1 border-gray-900 hover:rounded-xl"
          >
            {buttonText}
          </Button>
          </Link>
        </CardContent>        
      </Card>
    )
  }

  const CardTalk = () => {
    const header = "Talk to Accretion AI";
    const text = "Work with title documents simply by talking to them. Our frontier AI model takes the tedious tasks out of your daily workflow. "
    const buttonText = "Start now";
    const buttonLink = "/you"

    return (
      <ActionCard header={header} text={text} buttonText={buttonText} buttonLink={buttonLink} variant={"primary"}/>
    )
  }

  const CardEmail = () => {
    const header = "Email Accretion AI";
    const text = "Send Accretion AI an email with tasks and attachments. Accretion AI reads the attachments, and replies in a few seconds.";
    const buttonText = "AI@AccretionDB.com";
    const buttonLink = `mailto:ai@accretiondb.com?body=${encodeURIComponent("Hi Accretion AI, Can you help me with the following request?")}`

    return (
      <ActionCard header={header} text={text} buttonText={buttonText} buttonLink={buttonLink}/>
    )
  }

  return (
    <div 
      className="max-w-screen-xl px-4 grid grid-cols-1 lg:grid-cols-3"      
    >
      <div className="col-span-1 md:col-span-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">                   
          
          <div className="mx-auto my-8 md:col-span-2">
            
            <h1 className="font-bold md:text-5xl my-4 md:col-span-2">
              The AI Title Report Assistant 
            </h1>

            <h4>
            Built to make you extraordinarily productive,
            Accretion is the best way to automate title report with AI.
            </h4>

          </div>

          <div className="w-full h-full">
            <CardTalk/>
          </div>

          <div className="w-full h-full">
            <CardEmail/>
          </div>
        </div>
      </div>

      <div className="hidden w-full h-full lg:block lg:col-span-1 p-4">
        <img className="w-full h-full m-auto" src="/images/building-neural-net-sketch.svg" alt="title report neural net"/>
      </div>

      
        
    </div>
  );
}; 

const GetToKnow = () => {    

  const [activeItem, setActiveItem] = useState("item-1");

  const handleAccordionChange = (item) => {
    setActiveItem(item);    
  } 

  const ButtonGetToKnow = ({ buttonText, onClick }) => {
    return (
      <Button
        variant="outline"
        className="w-full text-lg border-1 border-gray-900 rounded-3xl hover:rounded-xl" 
        onClick={onClick}
      >
        {buttonText}
      </Button>
    )
  }

  const contentList = [
    { 
      value: "item-1", 
      url: "/videos/demo-video-email.mp4",
      title: "Email Accretion AI",
      icon: MdOutlineAttachEmail, 
      button: (
        <a href={`mailto:ai@accretiondb.com?body=${encodeURIComponent("Hi Accretion AI, Can you help me with the following request?")}`}>
          <ButtonGetToKnow buttonText={"Email AI@AccretionDB.com"}/>
        </a>
      ),
      content: (
        <div className="text-left text-lg">          
          <p className="my-0">
            <span className="inline-flex items-center">
              <CgAttachment className="inline w-6 h-6 mr-1 mt-1 flex-none" /> Attach your documents and ask Accretion AI questions. 
            </span> 
          </p>
          
          <p className="my-0">
            <span className="inline-flex items-center">
              <PiBookOpenText className="inline w-6 h-6 mr-1 mt-1 flex-none" /> Accretion AI reads your document and get an answer to you in no time.
            </span>
          </p>

          <p className="my-0">
            <span className="inline-flex items-center">
              <MdLoop className="inline w-6 h-6 mr-1 mt-1 flex-none" /> Email back and forth with Accretion AI. 
            </span>                        
          </p>          
        </div>
      )
    },
    { 
      value: "item-2", 
      url: "/videos/demo-video-talk-with-AI.mp4",
      title: "Talk with Accretion AI",
      icon: MdOutlineChat,
      button: (
        <Link to="/you">
          <ButtonGetToKnow buttonText={"Try it today"} onClick={scrollToTop}/>
        </Link>   
      ),
      content: (
        <div className="">
          <p className="text-left text-lg">
            Talk with Accretion AI on our website, using your best language: natural language. 
          </p>
        </div>
      )
    },
    { 
      value: "item-3", 
      url: "/videos/demo-video-mortgage-summary.mp4",
      title: "Build with Accretion",
      icon: MdOutlineBuild,
      button: (
        <Link to="/database">
          <ButtonGetToKnow buttonText={"Learn more"} onClick={scrollToTop}/>
        </Link> 
      ),
      content: (
        <div className="">
          <p className="text-left text-lg">
            Work with us to transform your database to be AI ready. 
          </p>          
        </div>
      )
    },
  ];

  return (
    <div className="max-w-screen-xl md:min-h-[80svh] px-4">
      <div className="text-left my-4">      
        <h1 className="font-bold my-4"> Get to Know Accretion </h1> 
        <h4 className="my-4"> 
          Accretion is building the best AI to automate real estate title production. 
        </h4> 
      </div> 

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">              

        <div className="w-full">
          <Accordion 
            type="single"              
            defaultValue="item-1" 
            className="w-full" 
            onValueChange={handleAccordionChange}
          >
            {contentList.map((item) => (
              <AccordionItem key={item.value} value={item.value} className="">
                
                <AccordionTrigger className="text-lg md:text-2xl font-bold">
                  <div className="flex">
                    <item.icon className="ml-0 mr-1" size={24} />
                    <span className="ml-1">{item.title}</span>
                  </div> 
                </AccordionTrigger>

                <AccordionContent className="min-h-64">
                  <div className="">
                  {item.content}
                  </div>
                  <div className="md:hidden">
                    {item.value !== "item-3" ? (
                      <DemoVideoCard 
                        videoFilepath={item.url}
                        isActive={activeItem === item.value}
                        aspectRatio={1.269}
                        loopLogic={true}
                        controlLogic={true}
                      />
                    ) : (                      
                      <img src={ImageBuild} alt="Build with Accretion" className="" />                      
                    )}
                  </div> 
                  <div className="text-center m-4">
                    {item.button}
                  </div>
                </AccordionContent>

              </AccordionItem>
            ))}
          </Accordion>
        </div>

        <div className="hidden mt-4 md:block md:w-full md:mx-4 p-2">
          {activeItem === "item-3" ? (
            <img src={ImageBuild} alt="Build with Accretion" className="w-full h-auto" />
          ) : (
            <DemoVideoCard 
              videoFilepath={contentList.find(item => item.value === activeItem)?.url}
              isActive={true}
              aspectRatio={1.269}
              className="border-2 border-gray-300 rounded-3xl"
            />
          )}
        </div> 
      </div>
    </div>
  );
};

const DemoLogin = () => {
  const [api, setApi] = useState(); 
  const [activeIndex, setActiveIndex] = useState(0);
  
  const contentList = [
    { title: "Do your best title work", url: "/videos/demo-video-talk-with-AI.mp4" },
    { title: "AI in your email inbox", url: "/videos/demo-video-email.mp4" },
  ]; 
  
  useEffect(() => {
    if (!api) return;

    api.on('select', () => {
      setActiveIndex(api.selectedScrollSnap());
      // console.log("api active Index ", api.selectedScrollSnap())
    });

    // Cleanup listener on component unmount
    return () => {
      api.off('select');
    };
  }, [api]);
    
  return (
    <div className="max-w-screen-xl">
        {/* <div className="text-left my-4">      
          <h1 className="font-bold my-4"> Join Accretion </h1>         
        </div>  */}
        <div className="grid grid-cols-1 md:grid-cols-2">        
        <div className="w-full m-auto col-span-1 p-2 mt-0 ">
          <h2 className="text-center font-bold my-4"> Join Accretion </h2>
          <h3 className="text-center font-normal my-4">
            {contentList[activeIndex].title}
          </h3>
          <LoginCard />
        </div>

        <div className="hidden mx-0 my-auto md:block col-span-1 p-2">
        <Carousel           
          className="w-full" 
          setApi={setApi}
          opts={{
            align: "center",
            loop: true,
            containScroll: "trimSnaps",
          }} 
          plugins={[
            Autoplay({
              delay: 8000,
            }),
          ]} 
        >
          <CarouselContent>
            {contentList.map((item, index) => (
                <CarouselItem key={index} >
                  <div className="p-0">
                    <DemoVideoCard 
                      videoFilepath={item.url} 
                      isActive={index===activeIndex}
                      className="rounded-3xl border-2 border-gray-400"
                      aspectRatio={1.269}
                    />
                  </div>
                </CarouselItem>
              ))}
          </CarouselContent> 
        </Carousel>    
        </div>

        </div>
      </div>
  )
}

const WhatWeDo = () => {
  const PanelTitleChain = () => {
    const contentList = [
      { url: "/videos/demo-video-search.mp4", title: "Auto complete your search with a visualized title chain." },
      { url: "/videos/demo-video-hover-detail.mp4", title: "Hover to see a short description." },
      { url: "/videos/demo-video-click-to-view-docs.mp4", title: "Click to see the document." },
    ];
  
    return (
      <TabContentVideoCard contentList={contentList}/>
    );
  };
  
  const PanelLegalDescription = () => {
    const contentList = [
      { url: "/videos/demo-video-find-legal-description.mp4", title: "Find legal descriptions in documents." },
      { url: "/videos/demo-video-compare-legal-description.mp4", title: "Validate legal description consistency." },
      { url: "/videos/demo-video-find-legal-description.mp4", title: "Find legal descriptions in documents." },
      { url: "/videos/demo-video-compare-legal-description.mp4", title: "Validate legal description consistency." }, 
    ];
  
    return (
      <TabContentVideoCard contentList={contentList}/>
    );
  };
  
  const PanelMortgage = () => {
    const contentList = [
      { url: "/videos/demo-video-mortgage-summary.mp4", title: "Summarize mortgage history. " },     
    ]; 
  
    return (
      <TabContentVideoCard contentList={contentList}/>
    );
  }; 
  
  const PanelLienDischarge = () => {
    const contentList = [
      { url: "/videos/demo-video-lien.mp4", title: "Find all the liens and discharges. " },     
    ]; 
  
    return (
      <TabContentVideoCard contentList={contentList}/>
    );
  };
  
  const PanelCheckUpdate= () => {
    const contentList = [
      { url: "/videos/demo-video-check-update.mp4", title: "Automatically get the latest records from county website. " },     
    ]; 
  
    return (
      <TabContentVideoCard contentList={contentList}/>
    );
  }; 
  
  const TabContentVideoCard = ({contentList}) => {        
    const [api, setApi] = useState(); 
    const [activeIndex, setActiveIndex] = useState(0);
    
    useEffect(() => {
      if (!api) return;
  
      api.on('select', () => {
        setActiveIndex(api.selectedScrollSnap());
        console.log("activeIndex ", api.selectedScrollSnap())
      });
  
      // Cleanup listener on component unmount
      return () => {
        api.off('select');
      };
    }, [api]);

    return (    
      <Carousel       
        setApi={setApi}
        className="w-full"
        opts={{
          align: "center",
          loop: true,
          containScroll: "trimSnaps",
        }}
        plugins={[
          Autoplay({
            delay: 20000,
          }),
        ]}      
      >
        <CarouselContent className="-ml-2 md:-ml-4">
          {contentList.map((item, index) => (
              <CarouselItem key={index} className="pl-2 md:pl-4 md:basis-4/5 lg:basis-2/3">
                <div className="p-4">
                  <DemoVideoCard 
                    videoFilepath={item.url} 
                    title={item.title} 
                    isActive={index === activeIndex} 
                    className="border-2 border-gray-300 rounded-3xl"
                  />
                </div>
              </CarouselItem>
            ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>    
    )
  }
  
  return (
    <div>      
      <div className="max-w-screen-xl px-4">
        <div className="text-left my-4">      
          <h1 className="font-bold my-4"> Do your best work with Accretion AI </h1> 
          <h4 className="my-4"> 
          Automate repetitive paperwork with Accretion AI. 
          </h4> 
        </div> 
      </div>      
       
      <Tabs defaultValue="title-chain" className="">        
        <div className="overflow-x-auto max-w-screen-xl px-4 my-8 justify-center">                

          <TabsList className="flex justify-center min-w-[640px] md:justify-start overflow-x-auto scrollbar-hide">
            <TabsTrigger className="w-1/5 px-2 flex-shrink-0" value="title-chain">Title chain</TabsTrigger>
            <TabsTrigger className="w-1/5 px-2 flex-shrink-0" value="legal-description">Legal description</TabsTrigger>
            <TabsTrigger className="w-1/5 px-2 flex-shrink-0" value="mortgage">Mortgage</TabsTrigger>
            <TabsTrigger className="w-1/5 px-2 flex-shrink-0" value="lien-discharge">Lien/discharge</TabsTrigger>
            <TabsTrigger className="w-1/5 px-2 flex-shrink-0" value="check-update">Check update</TabsTrigger>          
          </TabsList>
        </div>

        <TabsContent value="title-chain">
          <PanelTitleChain/>          
        </TabsContent>
        
        <TabsContent value="legal-description">
          <PanelLegalDescription/>
        </TabsContent>
        
        <TabsContent value="mortgage">
          <PanelMortgage/>
        </TabsContent>

        <TabsContent value="lien-discharge">
          <PanelLienDischarge/>
        </TabsContent>

        <TabsContent value="check-update">
          <PanelCheckUpdate/>
        </TabsContent>
      </Tabs>
    </div>
  )
}

const DemoExplorePlans = () => {
  return ( 
    <div className="max-w-screen-xl my-32"> 
      <h1 className="px-4 text-left font-bold">
        Explore Plans
      </h1>
      <ExplorePlans/>
    </div>
  )
}

export default function Demo () {
  const { isLoggedIn } = useAuth(); 
  
  return (    
    <div className='my-16'>      
      
      <div className="min-h-[70svh] flex justify-center items-center">
        <Slogan/>
      </div>

      <div className="my-32">
        <GetToKnow/>
      </div>

      { !isLoggedIn && (
          <div className="my-48">
          <DemoLogin/>
          </div>
        )
      }      

      <div className="my-32">
      <WhatWeDo/>
      </div>

      <div className="my-32 px-4">
      <DemoExplorePlans/>
      </div>            

    </div>
  )
}