import { useAuth } from './AuthContext'; 
import You from '../you/You.js';

import LoginCard from "./LoginCard.js";

import './user-auth.css';

const Login = () => {        
    const { isLoggedIn } = useAuth(); 

    if (isLoggedIn) {
        return (
            <You/>
        );
    }
  
    return (        
        <div className='max-w-screen-xl min-h-[50svh] flex'>
            <div className='w-full'>
                <h1 className='text-center my-16'>
                    Join Accretion 
                </h1>
                < LoginCard />
            </div>
        </div>        
    );
  };
  
export default Login;
