import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area"; 
import { 
    Sheet, 
    SheetContent,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import { Card } from "@/components/ui/card";
import { PiSidebarSimpleThin } from "react-icons/pi";

import { createQueryString_title_chat } from "@/helper/createQueryString";

const Sidebar = ({ chats = [] }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    
    let closeTimeout;

    const handleMouseEnter = () => {
        if (closeTimeout) clearTimeout(closeTimeout);
        setOpen(true);
    };

    const handleMouseLeave = () => {
        closeTimeout = setTimeout(() => {
            setOpen(false);
        }, 300); // 300ms delay before closing
    };

    const handleClick = ( chat ) => {
        console.log("handleCick: ", chat)
        if (!chat){
            return null;
        }                        
        const query_string = createQueryString_title_chat(chat); 
        console.log(query_string);
        navigate(query_string);
    }

    const timeSinceInWeeks = (isoTimestamp) => {
        const date = new Date(isoTimestamp);
        const now = new Date();
        
        // Calculate the difference in milliseconds and convert to weeks
        const differenceInMs = now - date;
        const differenceInWeeks = Math.floor(differenceInMs / (1000 * 60 * 60 * 24 * 7));
        
        return differenceInWeeks > 0 
          ? `${differenceInWeeks}w` 
          : "<1w";
    }

    return (
        <div className="h-full">
            <div 
                className="fixed left-0 top-0 w-12 h-full z-50 flex items-end justify-center"
                onMouseEnter={handleMouseEnter}
            >
                <div className="mb-8 w-full">
                <PiSidebarSimpleThin size={24}/>
                </div>
            </div>
            <Sheet open={open} onOpenChange={setOpen} >
                <SheetContent 
                    side="left" 
                    className="w-96 h-full p-0 mt-2 rounded-r-3xl bg-white/70 backdrop-blur-sm"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}                    
                >
                <SheetHeader className="p-4 border-b">
                    <SheetTitle className="flex items-center gap-2">                    
                    Chat History
                    </SheetTitle>
                </SheetHeader>
                {chats && (
                    <ScrollArea className="h-[calc(90vh-5rem)] w-full p-4">
                        {chats.length > 0 ? (
                        chats.slice().reverse().map((chat, index) => (
                            <Card 
                            key={index}
                            className="w-80 p-3 mb-2 hover:bg-gray-50 cursor-pointer transition-colors"
                            onClick={() => handleClick(chat)}
                            >
                            <p className="text-sm truncate">
                                {chat.addressOneLine}
                            </p>
                            <time className="text-xs text-gray-400">
                                {timeSinceInWeeks(chat.updated_at)} ago
                            </time>
                            </Card>
                        ))
                        ) : (
                        <div className="text-center text-gray-500 mt-8">
                            No chat history yet
                        </div>
                        )}
                    </ScrollArea>
                )}
                
                </SheetContent>
            </Sheet>
        </div>
    );
}; 

export { Sidebar };