import { createQueryString_getData, createQueryString_getDataHomeAI } from "./createQueryString";

// Title chat
const getData = async ({ propertyID }) => {
    const url_accretionDB_getData_v2 = process.env.REACT_APP_BACKEND_DOMAIN + "/api/doc-retrieval/doc-retrieval/";

    const queryString = createQueryString_getData({ propertyID });    

    if (queryString === null) {
        console.error("getData: queryString is null");
        return null; // Return null if the queryString is not valid
    }

    try {
        console.log("getData: Make API call to AccretionDB v2...");
        const response = await fetch(url_accretionDB_getData_v2 + queryString, {
            method: "GET",                 
        });

        console.log("getData: Complete API call to AccretionDB v2");

        if (response.ok) {
            const data = await response.json();

            if (response.status === 202) {
                console.log("retrieving in progress");
                return null; // Indicate that the task is still in progress
            } else {
                if (data.data[0].type === "not found") {
                    console.error("No records found in the county website");
                    return null; // Return an object indicating not found
                }                
                return data; // Return data if successful
            }
        } else {
            console.error("Failed to fetch data: ", response.statusText); 
            return null; // Return null for unsuccessful responses
        }
    } catch (error) {
        console.error("Error during fetch: ", error);
        return null; // Return null for errors
    }
};

const getChatHistory = async () => {
    const urlBackendGetProperties = process.env.REACT_APP_BACKEND_DOMAIN + "/api/user-data/recent-chats/";

    try {
        const response = await fetch(urlBackendGetProperties, {
            method: 'GET',
            credentials: 'include', // Ensure cookies are sent with the request
        });

        if (response.ok) {
            const data = await response.json();                        
            return data
        } else {
            const errorData = await response.json();
            console.error(errorData.error || "An error occurred while fetching properties.");
            return null; 
        }
    } catch (error) {
        console.error("An error occurred while fetching properties.");
        return null;

    }

};

const checkEmbeddingsAvailable = async (propertyID) => {
    const url_accretionDB_check_embedding = process.env.REACT_APP_BACKEND_DOMAIN + "/api/process-embedding/check-embedding/";

    try {
        const query_string = url_accretionDB_check_embedding + `?propertyID=${propertyID}`;        
        const response = await fetch(query_string, 
            {method: "GET"}
        );
        if (response.ok) {                        
            return true; 
        }
        throw new Error('Network response was not ok.');
    } catch (error) {
        console.error('Error checking embeddings:', error);
        return false;
    }
};

// Home AI
const getDataHomeAI = async ({ propertyID, address }) => {
    const url_accretionDB_getData = process.env.REACT_APP_BACKEND_DOMAIN + "/api/user-data/upload-data/";

    const queryString = createQueryString_getDataHomeAI({ propertyID, address });    

    if (queryString === null) {
        console.error("getDataHomeAI: queryString is null");
        return null; // Return null if the queryString is not valid
    }

    try {
        console.log("getDataHomeAI: Make API call to AccretionDB...");
        const response = await fetch(url_accretionDB_getData + queryString, {
            method: "GET",
            credentials: "include",
        });

        console.log("getDataHomeAI: Complete API call to AccretionDB");

        if (response.ok) {
            const data = await response.json();            
                                        
            return data; // Return data if successful            
        } else {
            console.error("Failed to fetch data: ", response.statusText); 
            return null; // Return null for unsuccessful responses
        }
    } catch (error) {
        console.error("Error during fetch: ", error);
        return null; // Return null for errors
    }
};

const checkEmbeddingsAvailableHomeAI = async (address) => {
    const url_accretionDB_check_embedding = process.env.REACT_APP_BACKEND_DOMAIN + "/api/process-embedding/check-embedding-user-data/";

    try {
        const query_string = url_accretionDB_check_embedding + `?address=${address}`;
        const response = await fetch(query_string, 
            {
                method: "GET", 
                credentials: "include",
            }
        );
        if (response.ok) {                        
            return true; 
        }
        throw new Error('Network response was not ok.');
    } catch (error) {
        console.error('Error checking embeddings:', error);
        return false;
    }
};


export {getData, getChatHistory, checkEmbeddingsAvailable, getDataHomeAI, checkEmbeddingsAvailableHomeAI};