import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from 'react';
import { DisplayGoogleMap, } from "@/helper/GoogleMapAPI";
import { Input } from "@/components/ui/input";
import { Upload, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Loader2 } from "lucide-react";
import { Separator } from '@/components/ui/separator.jsx';
import { useAuth } from "@/user-auth/AuthContext";
import LoginOverlay from "@/user-auth/login/LoginOverlay";
import { getCSRFToken } from "@/helper/getCSRFToken";
import { Progress } from "@/components/ui/progress";
import { Switch } from "@/components/ui/switch";
// import { Label } from '@/components/ui/label';
// import { useToast } from "@/components/hooks/use-toast";
// import { toast } from "sonner";


const urlBackendGetProperties = process.env.REACT_APP_BACKEND_DOMAIN + "/api/user-data/upload-data/";


const DocsUpload = ({ files, setFiles }) => {
    const [dragActive, setDragActive] = useState(false);    
  
    const onDrop = useCallback((acceptedFiles) => {
        const pdfFiles = acceptedFiles.filter(file => file.type === "application/pdf");
        if (pdfFiles.length < acceptedFiles.length) {
            alert("Only PDF files are allowed. Non-PDF files were ignored.");
        } else{
            setFiles(prev => [...prev, ...acceptedFiles]);
        }
        
    }, []);
  
    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      
      const droppedFiles = Array.from(e.dataTransfer.files);
      onDrop(droppedFiles);
    };
  
    const handleChange = (e) => {
      e.preventDefault();
      const selectedFiles = Array.from(e.target.files);
      onDrop(selectedFiles);
    };
  
    const removeFile = (indexToRemove) => {
      setFiles(prev => prev.filter((_, index) => index !== indexToRemove));
    };
  
    return (
      <Card className="w-full mx-auto rounded-3xl ">
        <CardContent className="pt-6">
          <div
            className={`relative rounded-3xl border-2 border-dashed p-6 text-center ${
              dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
            }`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Input
              type="file"
              multiple
              onChange={handleChange}
              className="hidden"
              id="file-upload"
              accept="application/pdf"
            />
            
            <div className="flex flex-col items-center gap-4 ">
                <label
                    htmlFor="file-upload"
                    className="min-h-80 w-full flex flex-col gap-0 justify-center items-center cursor-pointer text-base text-gray-600 "
                    accept="application/pdf"
                >
                    <div className="mb-0">
                        <Upload className="h-10 w-10 text-gray-400" />
                    </div>   
                    <div className="mt-0">
                        <span className="my-2 block">Drag and drop PDF files here, or click to upload. </span>                
                    </div>
                </label>
            </div>
          </div>
          <div className="max-h-96 overflow-y-scroll">
          {(files.length > 0) && (
            <div className="mt-6 space-y-3">
              {files.map((file, index) => (
                <Alert key={index} className="flex items-center justify-between p-2">
                  <AlertDescription className="text-sm">
                    {file.name} ({(file.size / 1024 / 1024).toFixed(1)} MB)
                  </AlertDescription>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => removeFile(index)}
                    className="h-8 w-8 flex-none"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </Alert>
              ))}
            </div>
          )}
          </div>
        </CardContent>
      </Card>
    );
};

const PhotoUpload = ({ files, setFiles }) => {
    const [dragActive, setDragActive] = useState(false);

    const allowedTypes = [
        "image/jpeg",
        "image/png",        
        "image/webp",        
        "image/bmp"
    ];
  
    const onDrop = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => allowedTypes.includes(file.type));
        if (imageFiles.length < acceptedFiles.length) {
            alert("Only image files (JPEG, PNG, GIF, etc.) are allowed. Some files were ignored.");
        } else{
            setFiles(prev => [...prev, ...acceptedFiles]);
        }        
    }, []);
  
    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      
      const droppedFiles = Array.from(e.dataTransfer.files);
      onDrop(droppedFiles);
    };
  
    const handleChange = (e) => {
      e.preventDefault();
      const selectedFiles = Array.from(e.target.files);
      onDrop(selectedFiles);
    };
  
    const removeFile = (indexToRemove) => {
      setFiles(prev => prev.filter((_, index) => index !== indexToRemove));
    };
  
    return (
      <Card className="w-full mx-auto rounded-3xl ">
        <CardContent className="pt-6 ">
        <div
            className={`relative rounded-3xl border-2 border-dashed p-6 text-center ${
                dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
            }`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
        <Input
            type="file"
            multiple
            onChange={handleChange}
            className="hidden"
            id="photo-upload"
            accept="image/*"
        />
        <div className="flex flex-col items-center gap-4 min-h-96">
            <label
                htmlFor="photo-upload"
                className="min-h-80 w-full flex flex-col gap-0 justify-center items-center cursor-pointer text-base text-gray-600"
                accept="image/*"
            >
                <div className="mb-0">
                    <Upload className="h-10 w-10 text-gray-400" />
                </div>   
                <div className="mt-0">
                    <span className="my-2 block">Drag and drop photos here, or click to upload. </span>
                </div>
              </label>
            </div>
          </div>
  
          <div className="max-h-96 overflow-y-scroll">
          {(files && files.length > 0) && (
            <div className="mt-6 space-y-3">
              {files.map((file, index) => (
                <Alert key={index} className="flex items-center justify-between p-2">
                  <AlertDescription className="text-sm">
                    {file.name} ({(file.size / 1024 / 1024).toFixed(1)} MB)
                  </AlertDescription>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => removeFile(index)}
                    className="h-8 w-8 flex-none"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </Alert>
              ))}
            </div>
          )}
          </div>
          
        </CardContent>
      </Card>
    );
};

// Delete the user uploaded data 
const DeleteUserData = async (address) => {
    const csrftoken = getCSRFToken(); 

    if (!address) {
        alert("No property ID found for deletion."); // Simple fallback instead of toast
        return null;
    }; 
    try {
        const response = await fetch(urlBackendGetProperties + `?address=${address}`, {
            method: "DELETE",
            credentials: 'include',
            headers: {
                'X-CSRFToken': csrftoken,
            }
        });

        if (response.ok) {
            alert("Deleted successfully."); // Replace with your notification system            
            return true; 
            // Notify parent component
            // if (onDeleteSuccess) {
            //     onDeleteSuccess(address);
            // }
        } else {
            const errorData = await response.json();
            console.error("Delete failed:", errorData);
            alert("Failed to delete. Please try again."); // Replace with your notification system
            
            return null;
        }
    } catch (error) {
        console.error("Error during delete:", error);
        alert("An error occurred. Please try again."); // Replace with your notification system
        return null;        
    }
};

const UserUpload = () => {    
    const { isLoggedIn } = useAuth();     
    const navigate = useNavigate(); // Initialize useNavigate
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [loadingProgress, setLoadingProgress] = useState(0); 
    const [docs, setDocs] = useState([]);
    const [pics, setPics] = useState([]);
    const [address, setAddress] = useState(null);
    const [propertyID, setPropertyID] = useState(null);
    const [dataPublic, setDataPublic] = useState(true);
    const [csrftoken, setCSRFToken] = useState(null); 

    useEffect(()=>{
        if (isLoggedIn) {
            setCSRFToken(getCSRFToken());             
        }
    },[isLoggedIn])

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const totalFiles = docs.length + pics.length;             
           // Upload docs one by one
            for (let i = 0; i < docs.length; i++) {
                setLoadingText(`${docs[i].name}`);                                
                
                const formData = new FormData();
                formData.append('address', address);
                formData.append('propertyID', propertyID);
                formData.append('visibility', dataPublic ? 'public' : 'private');
                formData.append('docs', docs[i]);

                // set timeout for the POST request
                const controller = new AbortController();
                const timeoutId = setTimeout(() => {
                    controller.abort();
                }, 10*60*1000); 

                const response = await fetch(urlBackendGetProperties, {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                    headers: {
                        'X-CSRFToken': csrftoken,
                    },
                });

                // Clear our custom timeout since request completed
                clearTimeout(timeoutId);

                if (!response.ok) {
                    throw new Error(`Failed to upload document: ${docs[i].name}`);
                }
                setLoadingProgress(Math.round(((i + 1) / totalFiles) * 100));
            }

            // Upload pics one by one
            for (let i = 0; i < pics.length; i++) {
                setLoadingText(`${pics[i].name}`);
                const formData = new FormData();
                formData.append('address', address);
                formData.append('propertyID', propertyID);
                formData.append('visibility', dataPublic ? 'public' : 'private');
                formData.append('pics', pics[i]);

                const response = await fetch(urlBackendGetProperties, {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                    headers: {
                        'X-CSRFToken': csrftoken,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Failed to upload picture: ${pics[i].name}`);
                };
                
                setLoadingProgress(Math.round(((i + 1 + docs.length) / totalFiles) * 100));
            }
           
            setIsLoading(false);                        
            setAddress(null);
            setPropertyID(null);
            setPics([]);
            setDocs([]);
            
            navigate(`/home-ai/?propertyID=${propertyID}&address=${address}`);
            
          } catch (error) {
            console.error('Upload error:', error);
            // toast({
            //   title: "Error",
            //   description: "Failed to upload files. Please try again.",
            //   variant: "destructive",
            // });
          } finally {
            setIsLoading(false);
          }

    }

    const handleVisibilityChange = (checked) => {
        setDataPublic(checked);        
    };


    const SeperatorLine = () => {
        return (
            <div className=''>
                <Separator className="h-[2px] bg-gray-900"/>
            </div>
        );
    }

    const isSubmitDisabled = !(docs.length > 0 && pics.length > 0 && address && propertyID);
    
    if (!isLoggedIn) {
        return (
            <LoginOverlay/>
        )
    }

    return (        
        <div className='p-4'>
        <div className="grid gap-4 max-w-screen-xl">
            <div className="w-full">
                <SeperatorLine/>
            </div>

            <div className="grid grid-col-1 md:grid-cols-5 w-full gap-4">                
                <div className="col-span-1 flex text-3xl ml-0 mt-0">
                    1. Address
                </div>
                <div className="col-span-4 w-full">
                    <DisplayGoogleMap setAddress={setAddress} setPropertyID={setPropertyID}/>
                </div>                
            </div>

            <div className="w-full">
                <SeperatorLine/>
            </div>
            <div className="grid grid-col-1 md:grid-cols-5 w-full gap-4">   
                <div className="col-span-1 flex text-3xl ml-0 mt-0">
                    2. Add Documents
                </div>
                <div className="col-span-4 w-full">
                    <DocsUpload files={docs} setFiles={setDocs}/>
                </div>
            </div>

            <div className="w-full">
                <SeperatorLine/>
            </div>
            <div className="grid grid-col-1 md:grid-cols-5 w-full gap-4">   
                <div className="col-span-1 flex text-3xl ml-0 mt-0">
                    3. Add photos
                </div>
                <div className="col-span-4 w-full">
                    <PhotoUpload files={pics} setFiles={setPics}/>
                </div>
            </div>

            <div className="w-full">
                <SeperatorLine/>
            </div>
            <div className="w-full grid grid-col-1 md:grid-cols-5 gap-4 ">   
                <div className="w-full col-span-1 flex flex-col gap-2 mt-0">
                    <div className="text-3xl ml-0">
                        4. Finish
                    </div>
                    <div className="flex w-full gap-2 ">                        
                        <Switch checked={dataPublic} onCheckedChange={handleVisibilityChange} />
                        <div className="ml-0">
                            {dataPublic ? 'Data Public' : 'Data Private'}
                        </div>
                    </div>
                    <div className="ml-0">
                        {dataPublic ? 'Your data is Public, you can share with anyone.' : 'Your Data is Private, only available to you.'}
                    </div>
                    
                </div>
                <div className="w-full col-span-4 space-y-4">
                    {(!address || !propertyID) && ( 
                        <p>*Please enter an address. </p>
                    )}
                    {(docs.length<1) && (
                        <p>*Please upload your property documents. </p>
                    )}
                    {(pics.length<1) && (
                        <p>*Please upload your property photos. </p>
                    )}                    
                    <Button 
                        variant="outline" 
                        className="w-full"
                        onClick={handleSubmit}
                        disabled={isLoading || isSubmitDisabled}
                    >
                        {isLoading ? (
                            <div className="flex gap-2 text-base">
                                <Loader2 className="animate-spin"/>
                                Uploading
                            </div>
                        ):(
                            "Submit to start Accretion AI"
                        )}
                    </Button>
                    {isLoading && (
                        <div >
                        <div className="text-center text-xs max-w-40 md:max-w-96 md:text-base truncate">
                            {loadingText}                            
                        </div>
                        <Progress value={loadingProgress} className="w-full bg-gray-200" indicatorClassName="bg-gray-900"/>
                        </div>
                    )}
                </div>
            </div>
        </div>        
        </div>
    )
};



export { UserUpload, DeleteUserData };