import Lottie from 'react-lottie';
import NotFound from "./animation-lotttie/not-found.json";
import ContactUsOverlay from '../../contact-us/ContactUsOverlay';
import GenerateTitleReport from '../share-save-edit/GenerateTitleReport';

const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: NotFound,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }    
};

export default function Error ({addressInfo, propertyID}) {

    return (
        <div className='max-w-xs flex flex-col gap-4'>            
            <div id='animation'>                
                <Lottie options={animationOptions}/>
            </div>  
            <div> 
                Contact Accretion Support Team 
            </div>
            <div className='w-full'>
                <ContactUsOverlay/>
            </div>
            {/* <GenerateTitleReport addressInfo={addressInfo} propertyID={propertyID}/>             */}
        </div>
    )
}