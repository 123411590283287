import { Card, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { FiUserPlus } from "react-icons/fi";

const CardProperty = ({ addressInfo, isLoading, onClick }) => {
    return (
      <Card 
        className="w-full rounded-xl h-32 cursor-pointer rounded-lg bg-gray-50 hover:bg-white justify-between border-1 border-gray-500 hover:rounded-3xl hover:shadow-lg"
        onClick={onClick}
      >
        <CardContent className="p-0 h-full flex flex-col text-left">
          {isLoading ? (
            <>
              <div className='text-lg mx-4'>
                <Skeleton className="h-6 w-full my-1" />
                <Skeleton className="h-6 w-full my-1" />
                <Skeleton className="h-6 w-full my-1" />
              </div>
            </>
          ) : (
            <>
              <div className='text-base mx-4'>
                <p className="my-2 truncate">{addressInfo.street_number} {addressInfo.route}</p>
                <p className="my-2 truncate">{addressInfo.locality}</p>
                <p className="my-2 truncate">{addressInfo.state} {addressInfo.zipcode}</p>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    );
};

const CardPropertyUserUpload = ({ address, previewLink, isLoading, onClick }) => {
  return (
    <Card 
      className="w-full rounded-xl h-32 cursor-pointer rounded-lg bg-gray-50 hover:bg-white justify-between border-1 border-gray-500 hover:rounded-3xl hover:shadow-lg"
      onClick={onClick}
    >
      <CardContent className="p-0 h-full flex flex-col text-left">
        {isLoading ? (
          <>
            <div className='text-sm mx-2'>
              <Skeleton className="h-20 w-full my-1" />              
              <Skeleton className="h-6 w-full my-1" />
            </div>
          </>
        ) : (
          <>
            <div className='text-sm mx-2'>
              <div className="h-20 hover:rounded-3xl overflow-hidden">
                <img src={previewLink} className="w-full rounded h-full object-cover " alt="Preview"/>
              </div>
              <div className="h-4 my-2 truncate">{address}</div>
            </div>              
          </>
        )}
      </CardContent>
    </Card>
  );
};

const CardRecentChat = ({ address, isLoading, onClick }) => {
  return (
    <Card 
      className="w-full rounded-xl h-full cursor-pointer rounded-lg bg-gray-50 hover:bg-white justify-between border-1 border-gray-500 hover:rounded-3xl hover:shadow-lg"
      onClick={onClick}
    >
      <CardContent className="p-0 h-full flex flex-col text-left">
        {isLoading ? (
          <>
            <div className='text-lg mx-8'>
              <Skeleton className="h-6 w-full my-1" />              
            </div>
          </>
        ) : (
          <>
            <div className='flex text-base mx-8'>
              <div className="ml-0 mr-2">
                <img src="/logo192.png" className="w-8"/>
              </div>
              <p className="my-2 truncate">{address}</p>
            </div>              
          </>
        )}
      </CardContent>
    </Card>
  );
};

const CardRecentChatUU = ({ address, isLoading, onClick }) => {
  return (
    <Card 
      className="w-full rounded-xl h-full cursor-pointer rounded-lg bg-gray-50 hover:bg-white justify-between border-1 border-gray-500 hover:rounded-3xl hover:shadow-lg"
      onClick={onClick}
    >
      <CardContent className="p-0 h-full flex flex-col text-left">
        {isLoading ? (
          <>
            <div className='text-lg mx-8'>
              <Skeleton className="h-6 w-full my-1" />              
            </div>
          </>
        ) : (
          <>
            <div className='flex text-base mx-8'>
              <div className="ml-0 mr-2">
                <FiUserPlus className="w-8"/>
              </div>
              <p className="my-2 truncate">{address}</p>
            </div>              
          </>
        )}
      </CardContent>
    </Card>
  );
};

export { CardProperty, CardRecentChat, CardRecentChatUU, CardPropertyUserUpload };