import { createContext, useContext, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./msalConfig";

const clientIdGoogle = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const urlBackendAuth = process.env.REACT_APP_BACKEND_DOMAIN + "/api/auth/";
const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState(null); // Store user information
    const [authProvider, setAuthProvider] = useState(null); // Track which provider was used    
    
    const checkAuth= async () => {        
        try {
            const response = await fetch(urlBackendAuth + "check-auth/", {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent with the request                
            });
            if (response.ok) {
                const data = await response.json();
                // console.log("check_auth data:", data)
                if (data.is_logged_in) {
                    setLoggedIn(true); 
                    setUser(data);
                    setAuthProvider(data.auth_provider)
                } else {
                    setLoggedIn(false); 
                }
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error checking session:', error);
            return false;
        }
    };

    const authGoogle = (token, provider) => {
        // verify google access token and create a auth session 
        fetch(urlBackendAuth + provider + "/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ "access_token": token, "provider": provider }),
        })
        .then(response => response.json())
        .then(data => {
            console.log("authGoogle data:", data)
            setUser(data); // Set user data from response
            setLoggedIn(true);
            setAuthProvider(provider);
        })
        .catch(error => console.error('Error:', error));
    };

    const authMicrosoft = async (accessToken, provider) => {
        try {
            const response = await fetch(urlBackendAuth + provider + "/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                credentials: 'include',
                body: JSON.stringify({ "access_token": accessToken, "provider": provider }),
            });
            const data = await response.json();
            if (data.success) {
                console.log("authMicrosoft data: ",data)
                setUser(data);
                setLoggedIn(true);
                setAuthProvider(provider);
            } else {
                console.error('Microsoft authentication failed:', data.message);
            }
        } catch (error) {
            console.error('Error during Microsoft authentication:', error);
        }
    };

    const logout = () => {    
        // Clear local storage/session storage
        localStorage.removeItem('userToken');
        sessionStorage.removeItem('userData');

        setUser(null);
        setLoggedIn(false);
        setAuthProvider(null);
    };

    return (
        
        <AuthContext.Provider value={{ isLoggedIn, user, authProvider, authGoogle, authMicrosoft, logout, checkAuth }}>
            <GoogleOAuthProvider clientId={clientIdGoogle}>
                <MsalProvider instance={msalInstance}>
                    {children}
                </MsalProvider>
            </GoogleOAuthProvider>
        </AuthContext.Provider>
        
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within AuthProvider');
    }
    return context;
};