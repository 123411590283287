import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../user-auth/AuthContext'; 
import { CardRecentChat, CardRecentChatUU } from '@/recommendation/CardProperty';
import { createQueryString_title_chat,createQueryString_home_AI } from '@/helper/createQueryString';
import { GoArrowRight } from "react-icons/go";
import { Button } from '@/components/ui/button';
import scrollToTop from '@/helper/scrollToTop';
import { Sidebar } from '@/components/sidebar/Sidebar';
import LoginCard from '@/user-auth/LoginCard';

import Error from '../database-demo/loading-error-handling/Error';

const urlBackendGetRecentChats = process.env.REACT_APP_BACKEND_DOMAIN + "/api/user-data/recent-chats/";

const getRecentChats = async (setChatsV1, setChatsUU, setError) => {
    try {
        const response = await fetch(urlBackendGetRecentChats, {
            method: 'GET',
            credentials: 'include', // Ensure cookies are sent with the request
        });
        if (response.ok) {
            const data = await response.json();
            setChatsV1(data.recent_chat_v1); 
            setChatsUU(data.recent_chat_user_upload);
            // console.log(data);
        } else {
            const errorData = await response.json();
            setError(errorData.error || "An error occurred while fetching properties.");
        };
    } catch (error) {
        console.error(error);
        setError(error);
    };
}

const RecentChatShort = () => {
    const { isLoggedIn } = useAuth();    
    const [chatsV1, setChatsV1] = useState([]);
    const [chatsUU, setChatsUU] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        if (isLoggedIn) {
            setLoading(true)
            getRecentChats(setChatsV1, setChatsUU, setError);
            setLoading(false);
        }; 
    }, [isLoggedIn]);
    
    const handleClickTitleChat = (property) => {        
        const queryString = createQueryString_title_chat(property);
        navigate(queryString);
    };

    const handleClickHomeAI = (userData) => {
        const queryString = createQueryString_home_AI(userData);
        navigate(queryString);
    };        

    if (error) {
        return (
            <div className='flex flex-col'>
                <div>
                    We have problem getting your recent chats.
                </div>
                <Error/>
            </div>  
        )
    } 

    return (
        <div>
            <div className="grid grid-cols-1 gap-3">
                {loading 
                    ? Array(4).fill().map((_, index) => <CardRecentChat key={index} isLoading={true} />)
                    : chatsUU.slice(0, 4).map(property => (
                        <CardRecentChatUU
                        key={property.propertyID}
                        address={property.address}
                        isLoading={false}
                        onClick={() => handleClickHomeAI(property)}
                        />
                    )) 
                }
                {loading 
                    ? Array(4).fill().map((_, index) => <CardRecentChat key={index} isLoading={true} />)
                    : chatsV1.slice(0, 4).map(property => (
                        <CardRecentChat
                        key={property.propertyID}
                        address={property.addressOneLine}
                        isLoading={false}
                        onClick={() => handleClickTitleChat(property)}
                        />
                    )) 
                }                    
            </div> 
            
        </div>
    );
}

const RecentChatAll = () => {
    const { isLoggedIn } = useAuth();    
    const [chatsV1, setChatsV1] = useState([]);
    const [chatsUU, setChatsUU] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        if (isLoggedIn) {
            setLoading(true)
            getRecentChats(setChatsV1, setChatsUU, setError);
            setLoading(false);
        }; 
    }, [isLoggedIn]);
    
    const handleClickTitleChat = (property) => {        
        const queryString = createQueryString_title_chat(property);
        navigate(queryString);
    };

    const handleClickHomeAI = (userData) => {
        const queryString = createQueryString_home_AI(userData);
        navigate(queryString);
    };        

    if (error) {
        return (
            <div className='flex flex-col'>
                <div>
                    We have problem getting your recent chats.
                </div>
                <Error/>
            </div>  
        )
    } 

    return (
        <div>
            <div className='flex justify-between w-full mb-2'>
                <div className="ml-0 text-left"> 
                    <div className='text-3xl'> Your conversations </div>
                    <div className='text-base'>Get back to your recent chats</div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-3">
                {loading 
                    ? Array(4).fill().map((_, index) => <CardRecentChat key={index} isLoading={true} />)
                    : chatsUU.map(property => (
                        <CardRecentChatUU
                        key={property.propertyID}
                        address={property.address}
                        isLoading={false}
                        onClick={() => handleClickHomeAI(property)}
                        />
                    )) 
                }
                {loading 
                    ? Array(4).fill().map((_, index) => <CardRecentChat key={index} isLoading={true} />)
                    : chatsV1.map(property => (
                        <CardRecentChat
                        key={property.propertyID}
                        address={property.addressOneLine}
                        isLoading={false}
                        onClick={() => handleClickTitleChat(property)}
                        />
                    )) 
                }                    
            </div> 
            
        </div>
    );
}

const Recents = () => {
    const { isLoggedIn} = useAuth(); 

    return (
        <div>
            <div className="">
                <Sidebar/>
            </div>
            {!isLoggedIn ? (
                <div className="min-h-[50svh] max-w-3xl flex flex-col w-full px-4 md:px-2">
                    <div className="text-3xl text-left w-full mt-0">
                        Your recent conversations
                    </div>
                    <LoginCard/>
                </div>
            ):(
                
                <div className="min-h-[80svh] max-w-3xl flex flex-col w-full px-4 md:px-2">
                    <div className="w-full mt-4">
                        <RecentChatAll/>
                    </div>
                </div>
            )}
            
        </div>
        
    )
}

const ButtonViewAllChats = () => {
    return (
        <Link to='/recents'>
            <Button variant="link" className='flex gap-2 text-black' onClick={()=>scrollToTop()}>                        
                View all
                <GoArrowRight/>
            </Button>
        </Link>            
    )
}

export { RecentChatShort, RecentChatAll, Recents, ButtonViewAllChats };