import { Helmet } from 'react-helmet';

import imageTitleChat from "./title-chat.png";
import imageTitleReport from "./title-report.png";
import imageFineTuning from './fine-tuning.png';
import imageExistingHomeSale from './‎chart-existing-home-sale.png';

import imageLLMRAG from '../database/v1/image-tech-spec.png';

import { ExplorePlans } from '@/database/v1_1/PlanCards';



const Slogan = () => {  
    return (
      <div className="max-w-screen-xl text-center px-4">
        <h1 className="font-bold md:text-5xl my-auto">
            Frontier AI Model For Title Report
        </h1>
        <div className="mx-auto my-8">
        <h4>
        Accretion AI is the best AI Model for automating title work. 
        </h4>         
        </div>      
      </div>
    );
}; 

const ContentFormat1 = ({ title, description, image }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-5 gap-8'>
            
            <div className='col-span-2 mt-0 w-full'>
                <h1>
                {title}
                </h1>
                <h4>
                {description}
                </h4>
            </div>

            <div className='col-span-3 w-full p-4 rounded-3xl border-2 border-gray-200'>
                {image}
            </div>
        </div>
    )
};

const ContentFormat2 = ({ title, description, image }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-5 gap-8'>
            
            <div className='md:hidden col-span-2 mt-0 w-full'>
                <h1>
                {title}
                </h1>
                <h4>
                {description}
                </h4>
            </div>

            <div className='col-span-3 w-full p-4 rounded-3xl border-2 border-gray-200'>
                {image}
            </div>

            <div className='hidden md:block col-span-2 mt-0 w-full'>
                <h1>
                {title}
                </h1>
                <h4>
                {description}
                </h4>
            </div>

            
        </div>
    )
};

const ContentNewWay = () => {
    const title = "A new way of title work in the age of AI";
    const description = "Use natural language to get title work done. Abstract real estate titles by simply talking to documents.";
    const image = <img src={imageTitleChat} alt="title chat"/>;
    return(
        <ContentFormat1 title={title} description={description} image={image}/>
    )
}

const ContentExamine = () => {
    const title = "Creating the most thorough examined title report";
    const description = "Real estate, the world's largest asset class, demands meticulous due diligence. Accretion is using AI to create the most thoroughly examined title reports with the click of a button.";
    const image = <img src={imageTitleReport} alt="title-report"/>;
    return(
        <ContentFormat2 title={title} description={description} image={image}/>
    )
}

const ContentTransform = () => {
    const title = "Transforming traditional documents to be AI ready";
    const description = "Turn your documents into a source of knowledge. Accretion helps you utilize your knowledge base to create the best AI for your enterprise.";
    const image = <img src={imageLLMRAG} alt="LLM with RAG"/>;
    return(
        <ContentFormat1 title={title} description={description} image={image}/>
    )
}

const ContentScale = () => {
    const title = "Scale workload to meet the cyclical market";
    const description = "Stay in sync with the cyclical real estate market, elastically spinning up or scaling down AI agents for your title workload.";
    const image = <img src={imageExistingHomeSale} alt="home sale chart"/>;
    return(
        <ContentFormat2 title={title} description={description} image={image}/>
    )
};

const ContentModel = () => {
    const title = "Tailoring AI models for your market";
    const description = "Deploy AI agents as the most experienced title abstractors in your specific market. Equivalent to years of training your workforce, Accretion's AI team helps you tailor AI models for your specific needs, adhering to your region's title guidelines.";
    const image = <img src={imageFineTuning} alt="fine tuning"/>;
    return(
        <ContentFormat1 title={title} description={description} image={image}/>
    )
};

export default function Ai () {            

    return (

        <div className="">
            <Helmet>
                <title> AI | A new of title work </title>
            </Helmet>

            <div className='max-w-screen-xl px-4 grid grid-cols-1 gap-8'>
                <div className='min-h-[50svh] flex justify-center items-center'>
                    <Slogan/>
                </div>

                <div className='my-16'>
                    <ContentNewWay/>
                </div>

                <div className='my-16'>
                    <ContentExamine/>
                </div>

                <div className='my-16'>
                    <ContentTransform/>
                </div>

                <div className='my-16'>
                    <ContentScale/>
                </div>

                <div className='my-16'>
                    <ContentModel/>
                </div>

                <div className='my-32'>
                    <h1 className="text-center">
                        Explore Plans
                    </h1>
                    <ExplorePlans/>
                </div>

            </div>                 

            {/* INDIVIDUAL INVESTORS */}
            {/* <div className="row" style={{marginTop: "5svh"}}>
                <div id="small-title">
                    Accretion AI for <strong>Individual Investors</strong>
                </div>
            </div> */}

            {/* <div className="segment">
                <div className="row">
                    <div id="title">
                        Be the expert of your own property
                    </div>                
                </div>
                <div className="row">
                    <div id="text">
                        Unlock endless possibilities through simple chats. 
                        Accretion AI, powered by the Accretion Database, gives you the insights you need to make informed decisions about the properties you own or are considering.
                    </div> 
                </div>
                <div className="row" id="image">
                    <img src={imageVisualizationCard} alt="title visualization"/>
                </div>
                <div className="row" style={{maxWidth:"500px", backgroundColor:"rgb(0,0,0,0.1)", borderRadius:"25px", padding:"5px, 15px, 5px, 15px", marginTop:"10px", marginBottom:"20px"}}>                    
                    <AnimationTypingAIForIndividual/>                    
                </div> 
                <div className="row">
                    <div id="text">
                        Accretion AI distills knowledge from real estate documents to answer any questions you have.                        
                    </div>                    
                </div>
                <div className="row" style={{maxWidth:"500px"}}>
                    <div className="column">                    
                        <ButtonTalkWithOurTeam />
                    </div>
                    <div className="column">
                        <ButtonTryAccretionAIToday/>
                    </div>
                </div>
                               
            </div> */}

        </div>
    )
}